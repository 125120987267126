import {Dispatch} from 'redux';
import axios, { AxiosRequestConfig } from 'axios';

import {TokenModel, ClassroomModel, SchoolModel, TeacherModel, ListModel, InfosModel, AccountModel } from '../models';

import { API_URL } from '../constantes';

import { setLoggedOut } from './accountActions';
import { getSchoolHeader, getSimpleHeader } from '../headers';

export const doList = async (dispatch:Dispatch, route:string, body:InfosModel, token:TokenModel, root:boolean, currentSchool:SchoolModel) => {
    let header = getSchoolHeader(token, root ? 0 : (token.school === 0 ? currentSchool?.id || 0 : token.school));
    const resp = await axios.post<ListModel>(API_URL + route, body, header);
    // .then(resp => dispatch({type: 'LIST_LOADED', list: resp.data.results, total: resp.data.info.total}))
    // .catch(() => dispatch(setLoggedOut()));
    return resp.data;
}

export const doLoadAdmins = async (dispatch:Dispatch, token:TokenModel, school:string|number) => {
    if(token) {
        await axios.get<Array<AccountModel>>(`${API_URL}/account/admin/list`, getSchoolHeader(token, school))
        .then(resp => dispatch({type:'ADMINS_LOADED', admins: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    } else {
        dispatch(setLoggedOut());
    }
}

export const doLoadSchools = async (dispatch:Dispatch, token:TokenModel) => {
    if(token) {
        await axios.get<Array<SchoolModel>>(`${API_URL}/school/list`, getSimpleHeader(token))
        .then(resp => dispatch({type:'SCHOOLS_LOADED', schools: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    } else {
        dispatch(setLoggedOut());
    }
}

export const doLoadClasses = async (dispatch:Dispatch, token:TokenModel, currentSchool:SchoolModel) => {
    if(token) {
        await axios.get<Array<ClassroomModel>>(`${API_URL}/classroom/list`, getSchoolHeader(token, currentSchool.id || 0))
        .then(resp => dispatch({type:'CLASSES_LOADED', classes: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    } else {
        dispatch(setLoggedOut());
    }
}

export const doLoadTeachers = async (dispatch:Dispatch, token:TokenModel, currentSchool:SchoolModel) => {
    if(token) {
        await axios.get<Array<TeacherModel>>(`${API_URL}/account/teacher/list`, getSchoolHeader(token, currentSchool.id || 0))
        .then(resp => dispatch({type:'TEACHERS_LOADED', teachers: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    } else {
        dispatch(setLoggedOut());
    }
}

export const doMassDelete = async (dispatch:Dispatch, route:string, ids:any[], header:AxiosRequestConfig) => {
    const dels = Promise.all(ids.map(id => {
        let path = `${API_URL}${route.substring(0, route.length - 1)}/${id}`;
        return axios.delete(path, header);
    }));
    try {
        await dels;
        return true;
    } catch (ex) {
        return false;
    }
}
