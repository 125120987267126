import * as React from "react";

import * as intl from "../../utils/intl";

import {CSVLink} from "react-csv";

import { Table, Row, Col, Divider, Input, Button as AntButton, TablePaginationConfig } from "antd";
import styled from "@emotion/styled";
import { SearchOutlined, ExportOutlined } from "@ant-design/icons";
import { useAccount } from "../../services";
import { InfosModel, initialInfos } from "../../services/models";
import { useList } from "../../services";
import { Redirect, useHistory } from "react-router";
import { getColumns, getExport, getHeaders } from "../../components/columns";
import LevelSelect from "../../components/Layout/LevelSelect"

const Button = styled(AntButton)`
  margin: 5px;
`;

const ButtonCol = styled(Col)`
  text-align: center;
`;

const SearchCol = styled(ButtonCol)`
  padding-top: 5px;
`;

const StatisticsTable = () => {
  const history = useHistory();

  const [level, setLevel] = React.useState<string>("0");

  const csv = React.useRef<any|null>(null);

  const { authentified } = useAccount();

  const { list, loadExportable } = useList(`${history.location.pathname}/${level}`);

  const [infos, setInfos] = React.useState<InfosModel>(initialInfos);
  const [current, setCurrent] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [listed, setListed] = React.useState<boolean>(false);
  const [exportable, setExportable] = React.useState<any[]>([]);
  
  const updateInfos = (pagination:TablePaginationConfig, filter:any, sorter:any) => {
    let info:InfosModel = {...infos,
      page: pagination.current ? pagination.current : 1,
      results: pagination.pageSize ? pagination.pageSize : 10,
      sortField: sorter.order ? sorter.field : undefined,
      sortOrder: sorter.order ? sorter.order : undefined,
    }
    setInfos(info);
  }
  
  const handleChangeLevel = (level:any) => {
    setLevel(level || 0);
  };

  const handleSearch = (searched:string) => {
    if(searched !== infos.search) {
      setInfos({...infos, page: 1, search: searched});
    }
  }
  
  const handleExport = () => {
    setExportable([]);
    loadExportable({...infos, page: 1, results: total})
    .then(e => setExportable(getExport(e, "statistics")));
  }

  React.useEffect(() => {
    const ac = new AbortController();
    setListed(false);
    list(infos).then(resp => {
      setTotal(resp.info.total);
      setCurrent(resp.results)
    })
    .finally(() => setListed(true));
    return () => ac.abort();
  }, [infos, list]);
  
  React.useEffect(() => {
    if (exportable?.length > 0 && csv?.current && csv?.current?.link) {
      setTimeout(() => csv.current.link.click());
    }
  }, [exportable]);

  const columns = getColumns("statistics");
  const headers = getHeaders("statistics");

  return !authentified ? <Redirect to="/" /> : <>
    <Divider orientation="left">{intl.get("layout.stats")}</Divider>
      <Row gutter={16}>
        <SearchCol span={4}><LevelSelect placeholder={intl.get("layout.level_select")} onChange={handleChangeLevel}/></SearchCol>
        <SearchCol span={18}>
          <Input.Search placeholder={intl.get("labels.search")} prefix={<SearchOutlined />} onSearch={handleSearch} allowClear/>
        </SearchCol>
        <ButtonCol span={2}>
          <Button type="primary" disabled={!listed || current.length === 0} icon={<ExportOutlined />} onClick={handleExport}>{intl.get("labels.export")}</Button>
          {exportable.length > 0 && <CSVLink data={exportable} headers={headers} separator=";" hidden ref={csv} filename={`statistics_export_${new Date().toLocaleString()}.csv`}/>}
        </ButtonCol>
      </Row>
      <Table key="statstics" loading={!listed}
          showSorterTooltip={false}
          onChange={updateInfos}
          rowKey={record => record.id}
          pagination={{ position: ['topCenter'], total: total, current: infos.page }}
          dataSource={listed ? current : []}
          columns={columns}/>
    </>;
};

export default StatisticsTable;
