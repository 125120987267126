import { createSelector } from 'reselect';

import {State as RootState} from '../rootReducer';
import {State} from './reducers';

export const isAuthentified = createSelector<RootState, State, any>(state => state.session, session => session.authentified);

export const isLoaded = createSelector<RootState, State, any>(state => state.session, session => session.loaded);

export const getToken = createSelector<RootState, State, any>(state => state.session, session => session.token);
export const isRoot = createSelector<RootState, State, any>(state => state.session, session => session.root);
export const getHeader = createSelector<RootState, State, any>(state => state.session, session => session.header);

export const getAccount = createSelector<RootState, State, any>(state => state.session, session => session.account);

export const getCurrentSchool = createSelector<RootState, State, any>(state => state.session, session => session.currentSchool);

export const getImportedSummary = createSelector<RootState, State, any>(state => state.session, session => session.importedSummary);
export const getImportedList = createSelector<RootState, State, any>(state => state.session, session => session.importedList);
export const getImportedTotal = createSelector<RootState, State, any>(state => state.session, session => session.importedTotal);
export const getImportedExported = createSelector<RootState, State, any>(state => state.session, session => session.importedExported);

export const getExported = createSelector<RootState, State, any>(state => state.session, session => session.exported);

export const getAdmins = createSelector<RootState, State, any>(state => state.session, session => session.admins);
export const getSchools = createSelector<RootState, State, any>(state => state.session, session => session.schools);
export const getClasses = createSelector<RootState, State, any>(state => state.session, session => session.classes);
export const getTeachers = createSelector<RootState, State, any>(state => state.session, session => session.teachers);
