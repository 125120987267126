import * as React from "react";

import {
  createStore,
  combineReducers,
  applyMiddleware,
  Middleware,
} from "redux";
import { Provider } from "react-redux";
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from "connected-react-router";
import * as History from "history";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

import { ThemeProvider } from "@emotion/react";

import rootReducer from "../rootReducer";
import { ThemeType } from "../types";

interface LireProviderProps {
  children: React.ReactNode;
}

const LireProvider = ({ children }: LireProviderProps) => {
  const history = History.createHashHistory();

  const reducer = combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  });
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["mylocal"],
  };
  const persistedReducer = persistReducer(persistConfig, reducer);

  const middlewares: Middleware[] = [routerMiddleware(history), thunk];
  if (process.env.NODE_ENV === "development") {
    middlewares.push(createLogger());
  }

  const store = createStore(
    persistedReducer,
    {},
    applyMiddleware(...middlewares)
  );
  const persistor = persistStore(store as any);

  const theme: ThemeType = {
    color: {
      primary: "#1976d2",
      light: "#bbdefb",
    },
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default LireProvider;
