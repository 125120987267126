import * as React from "react";

import * as intl from "../../utils/intl";

import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

import { Layout, Menu } from "antd";
import { SettingOutlined, BankOutlined, TeamOutlined, LineChartOutlined } from "@ant-design/icons";

import { useAccount } from "../../services";
import UserHeader from "../Layout/UserHeader"

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Logo = styled.img`
  height: 80px;
  padding: 12px;
`;

const MyContent = styled.div`
  padding: 24px;
  min-height: 360px;
  background-color: #fff;
`;

interface MainLayoutProps {
  children: React.ReactNode;
}

const rootPathes:string[] = ["/account/admins/root", "/schools", "/statistics/books"];

const MainLayout = ({ children }: MainLayoutProps) => {
  
  const location = useLocation();
  
  const { root, currentSchool } = useAccount();
  
  return (
    <Layout style={{ minHeight: "100vh" }} >
      <Sider theme="dark" collapsible={false} collapsed={false} width={260}>
        <Logo src="images/EABJM_LOGO_BLANC_2LIGNES.png"/>
        <Menu theme="dark" mode="inline" inlineIndent={15} 
            selectedKeys={[location.pathname]}
            defaultOpenKeys={rootPathes.indexOf(location.pathname) > -1 ? [] : ["sub"]} >
          <Menu.Item key="/account/admins/root" icon={<SettingOutlined />} hidden={!root}>
            <Link to="/account/admins/root">{intl.get("layout.admins_menu")}</Link>
          </Menu.Item>
          <Menu.Item key="/schools" icon={<BankOutlined />} hidden={!root}>
            <Link to="/schools">{intl.get("layout.schools_menu")}</Link>
          </Menu.Item>
          <SubMenu key="sub" icon={<TeamOutlined />} disabled={root && !currentSchool?.display}
              title={currentSchool ? `${intl.get("layout.school", undefined, {name: currentSchool.name})}` : intl.get("layout.noschool")}>
            <Menu.Item key="/account/admins">
              <Link to="/account/admins">{intl.get("layout.admins")}</Link>
            </Menu.Item>
            <Menu.Item key="/account/teachers">
              <Link to="/account/teachers">{intl.get("layout.teachers")}</Link>
            </Menu.Item>
            <Menu.Item key="/classrooms">
              <Link to="/classrooms">{intl.get("layout.classrooms")}</Link>
            </Menu.Item>
            <Menu.Item key="/account/students">
              <Link to="/account/students">{intl.get("layout.students")}</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/statistics/books" icon={<LineChartOutlined />}>
            <Link to="/statistics/books">{intl.get("layout.stats")}</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <UserHeader/>
        <Content style={{ margin: "16px 16px" }}>
          <MyContent>{children}</MyContent>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          {intl.get("layout.footer")}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
