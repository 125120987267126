import { AxiosRequestConfig } from 'axios';

import { TokenModel } from './models';

const getHeader = (headers:object):AxiosRequestConfig => {
    return {headers};
}

export const getTokenHeader = ():AxiosRequestConfig => {
    return getHeader({
        "Content-Type": "application/x-www-form-urlencoded",
    });
}

export const getSimpleHeader = (token:TokenModel):AxiosRequestConfig => {
    return getHeader({
        Authorization: `${token.token_type} ${token.access_token}`,
    });
}

export const getSchoolHeader = (token?:TokenModel, school:string|number = 0):AxiosRequestConfig => {
    return getHeader({
        Authorization: `${token?.token_type} ${token?.access_token}`,
        "X-School-ID": school,
    });
}

export const fixRootHeader = (root:boolean, header:AxiosRequestConfig):AxiosRequestConfig => {
    let fixedHeader = header;
    if(root) {
        fixedHeader.headers['X-School-ID'] = 0;
    }
    return fixedHeader;
}
