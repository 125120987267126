import React from "react";

import * as intl from "../../utils/intl";

import { Button, Select } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

import { Header } from "antd/lib/layout/layout";
import { SchoolModel } from "../../services/models";
import { useAccount } from "../../services";

const UserHeader = () => {

    const { account, root, schools, currentSchool, loadSchools, setCurrentSchool, logout } = useAccount();

    React.useEffect(() => {
        if(root) {
            loadSchools();
        }
    }, [root, loadSchools]);

    const handleSelectSchool = (value:string) => {
        setCurrentSchool(value || 0);
    }

    return root || currentSchool?.display ? <>
        <Header className="site-layout-background" style={{ padding: 0, height: 80, textAlign: "right" }}>
        <Select bordered={false} allowClear disabled={!root} style={{padding: "24px 0", textAlign: "right", minWidth: 190, color: "white", backgroundColor: "transparent"}} 
                value={currentSchool?.display || undefined} optionLabelProp={"label"}
                placeholder={intl.get("layout.header_select")} onChange={handleSelectSchool}>
            {schools?.map((school:SchoolModel) => (
                <Select.Option key={school.id} value={school.id || ""} label={currentSchool?.display}>
                    {school.name}
                </Select.Option>
            ))}
        </Select>
        <div style={{float: "right", padding: 8, color: "white"}} >
            {`${account.firstName} ${account.lastName}`}
            <Button type="text" danger={true} icon={<PoweroffOutlined />} onClick={logout}></Button>
        </div>
        </Header>
    </> : <></>;
}

export default UserHeader;