import * as React from "react";

import { Route, RouteProps, Redirect, useLocation } from "react-router";

import { useAccount } from "../../services";
import Progress from "../../components/Progress";

export interface SecureRouteProps extends RouteProps {
  children: React.ReactNode;
  loginPath: string;
}
/**
 * Custom Route to force authentification, if the user is not loggued, the page is redirect to login page.
 * ```
 * <SecureRoute loginPath="/login"><MyPage/></SecureRoute>
 * ```
 */
const SecureRoute = (props:SecureRouteProps) => {
  const { children, loginPath, ...rest } = props;

  const location = useLocation();

  const { loaded, authentified, login } = useAccount();

  React.useEffect(() => {
    login();
  },[login]);

  return (
    <Route {...rest}>
      {authentified ? (
        !loaded ? (
          <Progress />
        ) : 
          children
        ) : (
          <Redirect
            to={{ pathname: loginPath, state: { from: location.pathname } }}
          />
      )}
    </Route>
  );
};

export default SecureRoute;
