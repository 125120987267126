import * as React from "react";
import { keyframes, css } from "@emotion/react";
import styled from "@emotion/styled";

const MainProgress = styled.div(
  (props) => css`
    background-color: ${props.theme.color.primary};
    height: 4px;
    overflow: hidden;
    position: relative;
  `
);

const animation1 = keyframes`
    from { left: -35%; right: 100%; }
    60% { left: 100%; right: -90%;}
    to { left: 100%; right: -90%}
   `;
const animation2 = keyframes`
    from { left: -200%; right: 100%; }
    60% { left: 107%; right: -8%;}
    to { left: 107%; right: -8%}
  `;

type SubType = {
  animation: any;
  animationOpt: string;
};

const SubProgress = styled.div<SubType>(
  (props) => css`
    animation: ${props.animation} ${props.animationOpt};
    background-color: ${props.theme.color.light};
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
  `
);

const Progress = () => {
  return (
    <MainProgress data-testid="loading">
      <SubProgress
        animation={animation1}
        animationOpt="2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite"
      />
      <SubProgress
        animation={animation2}
        animationOpt="2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.15s infinite"
      />
    </MainProgress>
  );
};

export default Progress;
