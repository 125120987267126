import React from "react";

import * as intl from "../../utils/intl";

import { Button, Space, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

import { CheckOutlined, FileAddOutlined, FileDoneOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";

import { useHistory } from "react-router";
import { CSVLink } from "react-csv";
import { useImported } from "../../services";
import { getHeaders } from "../../components/columns";
import { IMPORT_OK } from "../../services/constantes";
import { shouldWait } from "./ImportStatus";

const RightSpace = styled(Space)`
    float: right;
    position: relative;
    right: -8px;
`;

const ImportHeader = (props:any) => {
    const history = useHistory();

    const csv = React.useRef<any|null>(null);

    const { importUpload } = useImported(history.location.pathname);

    const [fileList, setFileList] = React.useState<Array<UploadFile<any>>>([]);
    const [uploading, setUploading] = React.useState<boolean>(false);

    const handleBeforeUpload = (file:RcFile) => {
        setFileList([file]);
        props.fileUploaded(file.uid);
        return true;
    }

    const handleNewImport = () => {
        props.handleNewImport();
    }
    
    const uploadCSV = async (options:any) => {
        let upload = true;
        const form = new FormData();
        form.append("file", options.file);
        await importUpload(options.file.uid, form)
        .then((result) => {
            if(upload) {
                props.fileUploaded(options.file.uid);
                setUploading(true);
            }
        });
        return () => (upload = false);
    };

    const headers = getHeaders(props.object, true);

    React.useEffect(() => {
        setUploading(false);
    }, [props]);
        
    return <>
        <Space style={{lineHeight: 2}}>
            {intl.getHTML("import.title", undefined, {object: intl.get(`objects.${props.object}`, props.object)})}
            {props.statut === IMPORT_OK && <> - <Space style={{color: "green"}}>
                {intl.get(`import.status.${props.statut}`, props.statut)}<CheckOutlined style={{color: "#0A0"}}/>
            </Space></>}
        </Space>
        <RightSpace>
            {props.new ? <>
                <Upload accept=".csv" beforeUpload={handleBeforeUpload} fileList={fileList} showUploadList={false} customRequest={uploadCSV}>
                    <Button disabled={uploading || props.executing || props.executed} type="primary" icon={<FileAddOutlined />}>
                        {intl.get("import.file")}
                    </Button>
                </Upload>
                <Button type="ghost" icon={<FileDoneOutlined />} onClick={() => csv.current.link.click()}>
                    {intl.get("import.template")}
                </Button>
            </> : <Button key="new" disabled={shouldWait(props.statut)} onClick={handleNewImport} type="primary">{intl.get("import.new")}</Button>}
        </RightSpace>
        <CSVLink data={[]} headers={headers} separator=";" hidden ref={csv} filename={`${props.object}_import_template.csv`}/>
    </>
};

export default ImportHeader;