import * as React from "react";

import Layout from "../../components/Layout";
import { Switch, Route } from "react-router";

import AdminTable from "./AdminTable";
import AdminForm from "./AdminForm";
import SchoolTable from "./schoolTable";
import SchoolForm from "./SchoolForm";

const Global = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/account/admins/root" children={<AdminTable/>}/>
        <Route exact path="/account/admins/root/new" children={<AdminForm/>}/>
        <Route exact path="/account/admins/root/edit/:id" children={<AdminForm/>}/>
        
        <Route exact path="/schools" children={<SchoolTable/>}/>
        <Route exact path="/schools/new" children={<SchoolForm/>}/>
        <Route exact path="/schools/edit/:id" children={<SchoolForm/>}/>
      </Switch>
    </Layout>
  );
};

export default Global;
