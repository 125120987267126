import { SortOrder } from "antd/lib/table/interface";

export interface TokenModel {
    access_token?:string;
    expires_in?:number;
    role?:string;
    school:number;
    schoolName?:string;
    token_type?:string;
    user?:number;
    userRessource?:string; 
}

export const initialToken : TokenModel = {
    school: 0
}

export interface AccountModel {
    role?:string;
    id?:number;
    isMain:boolean;
    schoolID?:number;
    school?:string;
    firstName:string;
    lastName:string;
    login:string;
    password:string;
    dbLocalUpdate?:any;
    createdAt?:any;
    updatedAt?:any;
    tutorialStatuses?:any;
    booksProgress?:any;
}

export const initialAdmin : AccountModel = {
    isMain: false,
    schoolID: 0,
    firstName: "",
    lastName: "",
    login: "",
    password: "",
}

export interface InfosModel {
    page:number;
    results:number;
    sortField?:string;
    sortOrder:SortOrder;
    search?:string;
    action?:string;
    msgCode?:string;
    total:number,
    locale?:string;
}

export const initialInfos : InfosModel = {
    page: 1, 
    results: 10, 
    sortField: undefined,
    sortOrder: 'ascend',
    total: 0,
}

export interface ImportModel {
    new:boolean;
    total:number;
    update:number;
    insert:number;
    errors:number;
    warnings:number;
    messages:string[];
    status:string;
}

export const initialImport : ImportModel = {
    new: true,
    total: 0,
    update: 0,
    insert: 0,
    errors: 0,
    warnings: 0,
    messages: [],
    status: "STAND_BY",
}

export interface ListModel {
    results:Array<any>;
    info:{total:number};
}

export const initialList : ListModel = {
    results: [],
    info: {total:0},
}

export interface SchoolModel {
    id?:number;
    name:string;
    licenses?:number;
    licensesUsed?:number;
    admin?:AccountModel;
    adminID?:number;
    display?:string;
    removed:boolean;
}

export const initialSchool : SchoolModel = {
    name: "",
    licenses: 0,
    admin: undefined,
    removed: false,
}

export interface ClassroomModel {
    id?:number;
    name:string;
    description?:string;
    defaultLevel:number;
    school?:string;
    teacherId?:number;
    teacher?:TeacherModel;
    studentsCount?:number;
}

export const initialClassroom : ClassroomModel = {
    name: "",
    defaultLevel: 1,
}

export interface TeacherModel {
    id?:number;
    classRooms:Array<ClassroomModel>;
    firstName:string;
    lastName:string;
    login:string;
    password:string;
}

export const initialTeacher : TeacherModel = {
    classRooms: [],
    firstName: "",
    lastName: "",
    login: "",
    password: "",
}

export interface StudentModel {
    id?:number;
    currentXP?:number;
    currentLevel?:number;
    classroom?:number;
    classroomName?:string;
    schoolName?:string;
    bookSuggests?:Array<any>;
    messagesCount?:number;
    key?:number;
    firstName:string;
    lastName:string;
    login:string;
    password:string;
    email?:string;
}

export const initialStudent : StudentModel = {
    firstName: "",
    lastName: "",
    login: "",
    password: "",
}

export interface StatisticsModel {
    id:number;
    rank:any;
    name:string;
    days:number;
    uniqueReadCounter:number;
    monthReadCounter:number;
    progression:number;
}