import React from "react";

import * as intl from "../../utils/intl";

import { Form, Row, Col, Input, Button, Card, Divider, Skeleton, InputNumber, Modal, Select } from "antd";
import { InfoCircleOutlined, PlusCircleTwoTone, ExclamationCircleTwoTone, WarningTwoTone, ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useAccount, useEdited } from "../../services";
import { SchoolModel, initialSchool } from "../../services/models";

const layout = {
  labelCol: { offset: 2, span: 10 },
  wrapperCol: { offset: 2, span: 20 },
};

interface Params {
  id:string
}

const SchoolForm = () => {
  const history = useHistory();

  const params = useParams<Params>();

  const mode = params?.id ? "edit" : "create";

  const { admins, setCurrentSchool, loadAdmins, loadSchools } = useAccount();
  const { loadSchool, deleteSchool, saveSchool } = useEdited();
 
  const [current, setCurrent] = React.useState<SchoolModel>(initialSchool);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [deleted, setDeleted] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [saved, setSaved] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
    
  React.useEffect(() => {
    if(!loaded && params.id) {
      setCurrentSchool(Number(params.id));
      loadAdmins(params.id);
      loadSchool(params.id)
      .then(setCurrent)
      .catch(setError)
      .finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
  }, [loaded, params.id, loadAdmins, loadSchool, setCurrentSchool]);

  React.useEffect(() => {
    if(deleting) {
      setError("");
      deleteSchool(params.id)
      .then(setDeleted)
      .catch(setError)
      .finally(() => setDeleting(false));
    } else if (saving) {
      setError("");
      saveSchool(current)
      .then(setSaved)
      .catch(setError)
      .finally(() => {
        setSaving(false);
        if (params.id) {
          setCurrentSchool(Number(params.id));
        }
      });
    }}, [current, deleting, params.id, saving, deleteSchool, saveSchool, setCurrentSchool]);

  React.useEffect(() => {
    if(deleted) {
      setCurrentSchool(0);
      loadSchools();
    }
}, [deleted, loadSchools, setCurrentSchool]);

  const onFinish = (values:any) => {
    setCurrent(values);
    setSaving(true);
  }
  
  const handleDelete = () => {
    setAskDelete(false);
    setDeleting(true);
  };

  const rules = {
    lastName: [{ required: true, message: intl.get("form.rules.required") }],
    firstName: [{ required: true, message: intl.get("form.rules.required") }],
    login: [{ required: true, message: intl.get("form.rules.required") }],
    password: [{ required: true, message: intl.get("form.rules.required") }],
    email: [{ required: true, message: intl.get("form.rules.required") }],
    name: [{ required: true, message: intl.get("form.rules.required") }],
    licenses: [{ required: true, message: intl.get("form.rules.required") }],
    admin: [{ required: true, message: intl.get("form.rules.required") }],
  };

  const getPasswordHelp = () => {
    if (error && error.response.data === "INVALID_PASSWORD") {
      return intl.get("form.rules.password_admin", error.response.data);
    }
    return "";
  }

  return !loaded ? <Skeleton/> : (
    ((saved || deleted) && <Redirect push to={history.location.state ? String(history.location.state) : "/schools"}/>) ||
    <>
      <Form {...layout} layout="vertical" initialValues={{...current, adminID: current?.admin?.id}} onFinish={onFinish}>
        <Card title={intl.get(`form.title.${mode}_admins`)} style={{borderBottom: "none"}}
            extra={mode === "create" && error && 
              <span style={{fontSize: 16, color: "red"}}><WarningTwoTone twoToneColor={"orange"} style={{marginRight: 12}}/>
                {(error.response && intl.get(`problems.${error.response.data}`, `Une erreur est survenue: ${error.response.data}`)) ||
                (error.message && `Une erreur est survenue: ${error.message}`)}
              </span>}>
          {mode === "create" && <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={["admin", "lastName"]} label={intl.get("labels.columns.name")} rules={rules.lastName}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["admin", "firstName"]} label={intl.get("labels.columns.firstname")} rules={rules.firstName}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["admin", "login"]} label={intl.get("labels.columns.login")} rules={rules.login}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["admin", "password"]} label={intl.get("labels.columns.password")} rules={rules.password}help={getPasswordHelp()}
                  tooltip={{ title: intl.get("form.rules.password_admin"), icon: <InfoCircleOutlined /> }}>
                <Input.Password autoComplete="new-password" allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["admin", "email"]} label={intl.get("labels.columns.email")} rules={rules.email}>
                <Input allowClear/>
              </Form.Item>
            </Col>
          </Row>}
          {mode === "edit" && <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="adminID" label={intl.get("labels.columns.main_admin")} rules={rules.admin}>
                <Select disabled={admins?.length === 0}
                    suffixIcon={(admins?.length === 0 && <PlusCircleTwoTone onClick={() => history.push({pathname: "/account/admins/new", state: history.location.pathname})} twoToneColor="red"/>) || undefined}>
                  {admins?.map(admin => (<Select.Option key={admin.id} value={admin.id || ""}>{`${admin.lastName} ${admin.firstName} (${admin.login})`}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>
          </Row>}
        </Card>
        <Card title={intl.get(`form.title.${mode}_global_schools`)}
            extra={mode === "edit" && error && 
              <span style={{fontSize: 16, color: "red"}}><WarningTwoTone twoToneColor={"orange"} style={{marginRight: 12}}/>
                {(error.response && intl.get(`problems.${error.response.data}`, `Une erreur est survenue: ${error.response.data}`)) ||
                (error.message && `Une erreur est survenue: ${error.message}`)}
              </span>}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name={"name"} label={intl.get("labels.columns.school_name")} rules={rules.name}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"licenses"} label={intl.get("labels.columns.licenses")} rules={rules.licenses}>
                <InputNumber step={1} min={(current?.licensesUsed || 0) + 1} style={{width: "100%"}} onChange={value => {if(!Number(value)) {return}}}/>
              </Form.Item>
            </Col>
            {current.id !== undefined && <Col span={12} hidden>
              <Form.Item name="id" label="ID">
                <Input readOnly/>
              </Form.Item>
            </Col>}
          </Row>
          <Divider/>
          <Form.Item style={{marginBottom: 0}}>
            <Row style={{ textAlign: "center" }}>
              <Col span={8}>
                <Button onClick={() => setSaved(true)} icon={<ArrowLeftOutlined/>}>{intl.get("labels.back")}</Button>
              </Col>
              <Col span={8}>
                {mode === "edit" && <>
                  <Button loading={deleting} onClick={() => setAskDelete(true)} danger icon={<DeleteOutlined/>}>{intl.get("labels.delete")}</Button>
                  <Modal title={<ExclamationCircleTwoTone twoToneColor="red"/>} visible={askDelete} 
                      cancelText={intl.get("buttons.cancel")} okText={intl.get("buttons.valid")}
                      onOk={handleDelete} onCancel={() => setAskDelete(false)}>
                    <p>{intl.get("messages.delete_warning.schools")}</p>
                    <p>{intl.get("messages.sure")}</p>
                  </Modal>
                </>}
              </Col>
              <Col span={8}>
                <Button loading={saving} htmlType="submit" type="primary" icon={<SaveOutlined/>}>{intl.get("labels.save")}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Card>
      </Form>
    </>
  );
};

export default SchoolForm;
