import * as intl from "../utils/intl";

import { AxiosRequestConfig } from 'axios';
import { getSchoolHeader } from './headers';

import { TokenModel, AccountModel, ClassroomModel, SchoolModel, TeacherModel, ImportModel, initialToken, initialImport, initialSchool } from './models';

export interface State {
    type?:string;
    authentified?:boolean;
    loaded?:boolean;
    root?:boolean;
    token?:TokenModel;
    header?:AxiosRequestConfig;
    account?:AccountModel;
    currentSchool?:SchoolModel;
    selected:Array<any>;
    importedSummary:ImportModel;
    importedList?:Array<any>;
    importedTotal?:number;
    importedExported?:Array<any>;
    exported:Array<any>;
    admins?:Array<AccountModel>;
    schools?:Array<SchoolModel>;
    classes?:Array<ClassroomModel>;
    teachers?:Array<TeacherModel>;
}

const initialState : State = {
    authentified: false,
    loaded: false,
    root: false,
    token: initialToken,
    selected: [],
    importedSummary: initialImport,
    exported: [],
}

export const reducer = (state:State = initialState, current:State) => {
    switch(current.type) {
        case 'ACCOUNT_AUTHENTIFIED': {
            return {...state, authentified: true, token: current.token, root: current.token?.school === 0, header: getSchoolHeader(current.token, current?.token?.school)}
        }
        case 'SET_ACCOUNT': {
            return {...state, loaded: true, authentified: true, account: current.account}
        }
        case 'SCHOOL_CHANGED': {
            let params = {sco: current.currentSchool?.name, used: current.currentSchool?.licensesUsed, lic: current.currentSchool?.licenses};
            let display = current.currentSchool && intl.get("layout.header_select_content", undefined, params);
            let currentSchool = current.currentSchool ? {...current.currentSchool, removed: false, display} : {...initialSchool, removed: true};
            return {...state, currentSchool, header: getSchoolHeader(state.token, current.currentSchool?.id),
            }
        }
        case 'ADMINS_LOADED': {
            return {...state, admins: current.admins}
        }
        case 'SCHOOLS_LOADED': {
            return {...state, schools: current.schools}
        }
        case 'CLASSES_LOADED': {
            return {...state, classes: current.classes}
        }
        case 'TEACHERS_LOADED': {
            return {...state, teachers: current.teachers}
        }
        case 'IMPORT_SUMMARY_LOADED': {
            return {...state, importedSummary: current.importedSummary}
        }
        case 'IMPORT_LIST_LOADED': {
            return {...state, importedList: current.importedList, importedTotal: current.importedTotal}
        }
        case 'EXPORT_LOADED': {
            return {...state, exported: current.exported}
        }
        case 'LOGGED_OUT': {
            return initialState
        }
    }
    return state;
}
