import axios, { AxiosRequestConfig } from 'axios';

import { AccountModel, StudentModel, ClassroomModel, SchoolModel, TeacherModel } from '../models';

import { API_URL } from '../constantes';
import { fixRootHeader } from '../headers';

export const doLoadAdmin = async (id:any, header:AxiosRequestConfig) => {
    const resp = await axios.get<AccountModel>(`${API_URL}/account/admin/${id}`, header);
    return resp.data;
}

export const doDeleteAdmin = async (id:any, header:AxiosRequestConfig) => {
    await axios.delete(`${API_URL}/account/admin/${id}`, header);
    return true;
}

export const doSaveAdmin = async (admin:AccountModel, root:boolean, header:AxiosRequestConfig) => {
    if(admin.id) {
        await axios.put(`${API_URL}/account/admin/${admin.id}`, admin, fixRootHeader(root, header));
    } else {
        await axios.post(`${API_URL}/account/admin`, admin, fixRootHeader(root, header));
    }
    return true;
}

export const doLoadSchool = async (id:any, header:AxiosRequestConfig) => {
    const resp = await axios.get<SchoolModel>(`${API_URL}/school/${id}`, header);
    return resp.data;
}

export const doDeleteSchool = async (id:any, header:AxiosRequestConfig) => {
    await axios.delete(`${API_URL}/school/${id}`, header);
    return true;
}

export const doSaveSchool = async (school:SchoolModel, header:AxiosRequestConfig) => {
    if(school.id) {
        await axios.put(`${API_URL}/school/${school.id}`, school, header);
    } else {
        await axios.post(`${API_URL}/school`, school, header);
    }
    return true;
}

export const doLoadClassroom = async (id:any, header:AxiosRequestConfig) => {
    const resp = await axios.get<ClassroomModel>(`${API_URL}/classroom/${id}`, header);
    return resp.data;
}

export const doDeleteClassroom = async (id:any, header:AxiosRequestConfig) => {
    await axios.delete(`${API_URL}/classroom/${id}`, header);
    return true;
}

export const doSaveClassroom = async (classroom:ClassroomModel, header:AxiosRequestConfig) => {
    if(classroom.id) {
        await axios.put(`${API_URL}/classroom/${classroom.id}`, classroom, header);
    } else {
        await axios.post(`${API_URL}/classroom`, classroom, header);
    }
    return true;
}

export const doLoadTeacher = async (id:any, header:AxiosRequestConfig) => {
    const resp = await axios.get<TeacherModel>(`${API_URL}/account/teacher/${id}`, header);
    return resp.data;
}

export const doDeleteTeacher = async (id:any, header:AxiosRequestConfig) => {
    await axios.delete(`${API_URL}/account/teacher/${id}`, header);
    return true;
}

export const doSaveTeacher = async (teacher:TeacherModel, header:AxiosRequestConfig) => {
    if(teacher.id) {
        await axios.put(`${API_URL}/account/teacher/${teacher.id}`, teacher, header);
    } else {
        await axios.post(`${API_URL}/account/teacher`, teacher, header);
    }
    return true;
}

export const doLoadStudent = async (id:any, header:AxiosRequestConfig) => {
    const resp = await axios.get<StudentModel>(`${API_URL}/account/student/${id}`, header);
    return resp.data;
}

export const doDeleteStudent = async (id:any, header:AxiosRequestConfig) => {
    await axios.delete(`${API_URL}/account/student/${id}`, header);
    return true;
}

export const doSaveStudent = async (student:StudentModel, header:AxiosRequestConfig) => {
    if(student.id) {
        await axios.put(`${API_URL}/account/student/${student.id}`, student, header);
    } else {
        await axios.post(`${API_URL}/account/student`, student, header);
    }
    return true;
}
