import React from "react";

import * as intl from "../../utils/intl";

import { CheckCircleTwoTone, CloseCircleTwoTone, WarningTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import { Space, Spin, Typography } from "antd";

export const shouldWait = (status:string = "STAND_BY") => {
    return status === "NOT_FOUND" || status === "ANALYSING" || status === "UPLOADING";
};

export const displayStatus = (status:string = "STAND_BY") => {
    switch (status) {
    case "STAND_BY": return <Typography.Title level={3} style={{color: "lightgrey"}}>{intl.get("import.status.STAND_BY")}</Typography.Title>
    case "EMPTY_CSV": return <Space>
        <Typography.Title level={3} style={{color: "red"}}>{intl.get("import.status.EMPTY_CSV")}</Typography.Title>
        <CloseCircleTwoTone twoToneColor="red" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "ERROR": return <Space>
        <Typography.Title level={3} style={{color: "red"}}>{intl.get("import.status.ERROR")}</Typography.Title>
        <CloseCircleTwoTone twoToneColor="red" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "NOT_FOUND": return <Space>
        <Typography.Title level={3} style={{color: "grey"}}>{intl.get("import.status.NOT_FOUND")}</Typography.Title>
        <CloseCircleTwoTone twoToneColor="grey" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "NOT_ENOUGH_LICENSES": return <Space>
        <Typography.Title level={3} style={{color: "orange"}}>{intl.get("import.status.NOT_ENOUGH_LICENSES")}</Typography.Title>
        <WarningTwoTone twoToneColor="orange" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "ANALYSING": return <Space>
        <Typography.Title level={3} style={{color: "goldenrod"}}>{intl.get("import.status.ANALYSING")}</Typography.Title>
        <Spin size="default" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "UPLOADING": return <Space>
        <Typography.Title level={3} style={{color: "goldenrod"}}>{intl.get("import.status.UPLOADING")}</Typography.Title>
        <Spin size="default" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    case "OK": return <Space>
        <Typography.Title level={3} style={{color: "green"}}>{intl.get("import.status.OK")}</Typography.Title>
        <CheckCircleTwoTone twoToneColor="green" style={{fontSize: 22, lineHeight: 1.5}}/></Space>;
    default: return <QuestionCircleTwoTone twoToneColor="pink" style={{fontSize: 22, lineHeight: 1.5}}/>;
    }
};
