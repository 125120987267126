import {Dispatch} from 'redux';
import axios, { AxiosRequestConfig } from 'axios';

import { ListModel, InfosModel, ImportModel, initialImport } from '../models';

import { API_URL } from '../constantes';

import { setLoggedOut } from './accountActions';
import { fixRootHeader } from '../headers';

export const doImportUpload = async (dispatch:Dispatch, route:string, body:FormData, header:AxiosRequestConfig, root:boolean) => {
  return await axios.post(API_URL + route, body, fixRootHeader(root, header))
  .then(() => {return true})
  .catch(() => {
      dispatch(setLoggedOut());
      return false;
  });
}

export const doImportedSummary = async (dispatch:Dispatch, route:string, header:AxiosRequestConfig) => {
  await axios.get<ImportModel>(API_URL + route, header)
  .then(resp => dispatch({type: 'IMPORT_SUMMARY_LOADED', importedSummary: {...resp.data, new: false}}))
  .catch(() => dispatch(setLoggedOut()));
}

export const doImportedList = async (dispatch:Dispatch, route:string, body:InfosModel, header:AxiosRequestConfig) => {
  let filters = [
    ...(body.action ? [`action=${body.action}`] : []),
    ...(body.msgCode ? [`msgCode=${body.msgCode}`] : []),
  ];
  await axios.post<ListModel>(`${API_URL}${route}${filters && `?${filters.join("&")}`}`, body, header)
  .then(resp => dispatch({type: 'IMPORT_LIST_LOADED', importedList: resp.data.results, importedTotal: resp.data.info.total}))
  .catch(() => dispatch(setLoggedOut()));
}

export const doImportedExported = async (route:string, body:InfosModel, header:AxiosRequestConfig) => {
  const resp = await axios.post<ListModel>(API_URL + route + "?states=ERROR", body, header);
  return resp.data.results;
}

export const doExecuteImport = async (route:string, header:AxiosRequestConfig, root:boolean) => {
  await axios.post<ListModel>(API_URL + route, undefined, fixRootHeader(root, header));
}

export const doClearImport = async (dispatch:Dispatch) => {
  dispatch({type: 'IMPORT_SUMMARY_LOADED', importedSummary: initialImport});
}

export const doExportable = async (route:string, body:InfosModel, header:AxiosRequestConfig) => {
  const resp = await axios.post<ListModel>(API_URL + route, body, header);
  return resp.data.results;
}
