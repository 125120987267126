import {Dispatch} from 'redux';
import axios from 'axios';

import {TokenModel, AccountModel, SchoolModel } from '../models';

import { API_URL } from '../constantes';
import { getTokenHeader, getSimpleHeader } from '../headers';

export const setLoggedOut = () => ({type:'LOGGED_OUT'});

export const doGetToken = async (dispatch:Dispatch, values:any) => {
    const route = `${API_URL}/account/token`;
    const data = `grant_type=client_credentials&username=${values.login}&password=${values.pass}`;
    const resp = await axios.post<TokenModel>(route, data, getTokenHeader());
    dispatch({type:'ACCOUNT_AUTHENTIFIED', token: resp.data});
    doSetCurrentSchool(dispatch, resp.data);
    return resp.data.role === "Admin";
}

export const doSetCurrentSchool = async (dispatch:Dispatch, token:TokenModel, id:number|string = token?.school) => {
    if(id === 0) {
        dispatch({type:'SCHOOL_CHANGED', currentSchool: undefined});
    } else {
        const route = `${API_URL}/school/${id}`;
        await axios.get<SchoolModel>(route, getSimpleHeader(token))
        .then(resp => dispatch({type:'SCHOOL_CHANGED', currentSchool: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    }
}

export const doLogin = async (dispatch:Dispatch, token:TokenModel) => {
    if(token) {
        const route = `${API_URL}${token.userRessource}`;
        await axios.get<AccountModel>(route, getSimpleHeader(token))
        .then(resp => dispatch({type:'SET_ACCOUNT', loaded:true, account: resp.data}))
        .catch(() => dispatch(setLoggedOut()));
    } else {
        dispatch(setLoggedOut());
    }
}

export const doLogout = (dispatch:Dispatch) => {
    dispatch(setLoggedOut());
}
