import * as React from "react";

import * as intl from "../../utils/intl";

import {CSVLink} from "react-csv";

import { Table, Row, Col, Divider, Input, Button as AntButton, TablePaginationConfig, Modal } from "antd";
import styled from "@emotion/styled";
import { ExclamationCircleTwoTone, PlusOutlined, EditOutlined, DeleteOutlined, SearchOutlined, ImportOutlined, ExportOutlined, } from "@ant-design/icons";

import { Redirect, useHistory } from "react-router-dom";
import { useAccount, useList } from "../../services";
import { InfosModel, initialInfos } from "../../services/models";
import { getColumns, getHeaders, getExport } from "../../components/columns";
import ImportModal from "../Import/ImportModal";

const Button = styled(AntButton)`
  margin: 5px;
`;

const ButtonCol = styled(Col)`
  text-align: center;
`;

const SearchCol = styled(Col)`
  padding-top: 5px;
`;

const GlobalTable = (props:any) => {
  const history = useHistory();

  const csv = React.useRef<any|null>(null);

  const { authentified, account } = useAccount();
  const { list, massDelete, loadExportable } = useList(history.location.pathname.replace("/root", ""), true);

  const [infos, setInfos] = React.useState<InfosModel>(initialInfos);
  const [current, setCurrent] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState<number>(0);
  const [listed, setListed] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any[]>([]);
  const [doImport, setDoImport] = React.useState(false);
  const [exportable, setExportable] = React.useState<any[]>([]);

  const updateInfos = (pagination:TablePaginationConfig, filter:any, sorter:any) => {
    let info:InfosModel = {...infos,
      page: pagination.current ? pagination.current : 1,
      results: pagination.pageSize ? pagination.pageSize : 10,
      sortField: sorter.order ? sorter.field : undefined,
      sortOrder: sorter.order ? sorter.order : undefined,
    }
    setInfos(info);
  }

  const handleSearch = (searched:string) => {
    setInfos({...infos, page: 1, search: searched});
  }

  const handleDelete = () => {
    setDeleting(true);
    massDelete(selected.map(s => s.id))
    .finally(() => {
      setSelected([]);
      setRefresh(!refresh);
    });
  }
  
  const handleAbort = (reload:boolean) => {
    if(reload) {
      setRefresh(!refresh);
    }
    setDoImport(false);
  }
  
  const handleExport = () => {
    setExportable([]);
    loadExportable({...infos, page: 1, results: total})
    .then(e => setExportable(getExport(e, props.object)));
  }

  React.useEffect(() => {
    const ac = new AbortController();
    setAskDelete(false);
    setDeleting(false);
    setListed(false);
    list(infos).then(resp => {
      setTotal(resp.info.total);
      setCurrent(resp.results)
    })
    .finally(() => setListed(true));
    return () => ac.abort();
  }, [infos, list, refresh]);

  React.useEffect(() => {
    if (exportable?.length > 0 && csv?.current && csv?.current?.link) {
      setTimeout(() => csv.current.link.click());
    }
  }, [exportable]);

  const columns = getColumns(props.object);
  const headers = getHeaders(props.object);

  return !authentified ? <Redirect to="/" /> : <>
    <Divider orientation="left">{intl.get(`lists.global_${props.object}`)}</Divider>
    <Row gutter={16}>
      <ButtonCol span={3}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => history.push(`${history.location.pathname}/new`)}></Button>
        <Button type="ghost" disabled={selected.length !== 1} icon={<EditOutlined />} onClick={() => history.push(`${history.location.pathname}/edit/${selected[0]?.id}`)}></Button>
        {props.object === "schools" ? <></> : <>
          <Button type="primary" danger icon={<DeleteOutlined />} loading={deleting} onClick={() => setAskDelete(true)}
              disabled={selected.length === 0 || selected.find(s => s.id === account.id) || total === 1}/>
          <Modal title={<ExclamationCircleTwoTone twoToneColor="red"/>} visible={askDelete} 
              cancelText={intl.get("buttons.cancel")} okText={intl.get("buttons.valid")}
              onOk={handleDelete} onCancel={() => setAskDelete(false)}>
            <p>{intl.get("messages.delete", undefined, {nb: "", obj: selected[0]?.login, one: 1})}</p>
          </Modal>
        </>}
      </ButtonCol>
      <SearchCol span={17}>
        <Input.Search placeholder={intl.get("labels.search")} prefix={<SearchOutlined />} onSearch={handleSearch} allowClear/>
      </SearchCol>
      <ButtonCol span={4}>
        <Button onClick={() => setDoImport(true)} type="primary" icon={<ImportOutlined />}>{intl.get("labels.import")}</Button>
        <ImportModal object={props.object} visible={doImport} onAbort={handleAbort}/>
        <Button type="primary" disabled={!listed || current.length === 0} icon={<ExportOutlined />} onClick={handleExport}>{intl.get("labels.export")}</Button>
        {exportable.length > 0 && <CSVLink data={exportable} headers={headers} separator=";" hidden ref={csv} filename={`${props.object}_export_${new Date().toLocaleString()}.csv`}/>}
      </ButtonCol>
    </Row>
    <Table key={props.object} loading={!listed}
        showSorterTooltip={false}
        onChange={updateInfos}
        rowKey={record => record.id}
        pagination={{ position: ['topCenter'], total: total, current: infos.page, showSizeChanger: true,
          pageSizeOptions: ["10", 
            ...(total > 20 ? ["20", ...(total < 50 ? [String(total)] : [])] : [...total > 10 ? [String(total)] : []]), 
            ...(total > 50 ? ["50", ...(total < 100 ? [String(total)] : [])] : []), 
            ...(total > 100 ? ["100", String(total)] : [])
          ]
        }}
        dataSource={listed ? current : []}
        columns={columns}
        rowSelection={{ type: "radio", preserveSelectedRowKeys: true, onChange: (keys, rows) => setSelected(rows) }}/>
  </>;
};

export default GlobalTable;
