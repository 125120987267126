import * as React from "react";

import * as intl from "../../utils/intl";

import { Row, Col, Form, Input, Button, Card, Skeleton } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Redirect } from "react-router";

import { useAccount } from "../../services";

import Flags from "../../components/Layout/Flags"

import styled from "@emotion/styled";

const LoginLogo = styled.img`
  background-color: white;
  width: 100%;
  padding: 28px;
`;

const Login = (props:any) => {
  
  const { authentified, root, getToken } = useAccount();

  const [locale, setLocale] = React.useState<string>();

  const [tried, setTried] = React.useState<boolean>(false);
  const [current, setCurrent] = React.useState();
  const [logging, setLogging] = React.useState<boolean>(false);
  const [right, setRight] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>(false);

  const [rules, setRules] = React.useState({login: [{}], password: [{}]});

  const handleAuth = (values:any) => {
    setTried(true);
    setCurrent(values);
    setLogging(true);
    setError(undefined);
  }

  React.useEffect(() => {
    if(logging) {
      getToken(current)
      .then(setRight)
      .catch(setError)
      .finally(() => setLogging(false));
    }
  }, [current, logging, getToken]);

  React.useEffect(() => {
    setRules({
      login: [{required: true, message: intl.get("messages.login")}],
      password: [{required: true, message: intl.get("messages.password")}]
    });
  }, [locale]);

  return authentified && right ? <Redirect to={`/account/admins${root ? "/root" : ""}`}/> : <>
    <Row justify="center" align="middle" style={{ minHeight: "100vh", backgroundColor: "#eeeeee" }}>
      <Col xs={{ span: 6 }}>
        <Form onFinish={handleAuth}>
          <LoginLogo src="images/EABJM_LOGO_COULEUR_2LIGNES.png" className="ant-card"/>
          <Card title={intl.get("login.title")} extra={<Flags onSelect={(locale:string) => {props.setCurrentLocale(locale);setLocale(locale);}}/>}>
            {tried && !logging ?
              <p style={{color: "red"}}>
                {(error && intl.get("messages.credentials")) || (!right && intl.get("messages.rights"))}
              </p> : <></>
            }
            <Row>
              <Col span={24}>
                <Form.Item name="login" rules={rules.login} style={{width: '100%'}}>
                  {logging ? <Skeleton.Input active style={{width: '100%'}}/> : <Input placeholder={intl.get("login.login")} prefix={<UserOutlined />}/>}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="pass" rules={rules.password} style={{width: '100%'}}>
                  {logging ? <Skeleton.Input active style={{width: '100%'}}/> : <Input type="password" placeholder={intl.get("login.password")} prefix={<LockOutlined />}/>}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button loading={logging} block type="primary" htmlType="submit">{intl.get("login.buttonLogin")}</Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Col>
    </Row>
  </>
};

export default Login;
