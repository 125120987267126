export const API_URL = (window.location.toString().indexOf('localhost') < 0) ? './api' : localStorage.ApiUrl || 'https://jeannine-dev.azurewebsites.net/api';

export const PRIMARY = '#3A3A3A';
export const LIGHT = '#B5ACAF';
export const ACCENT = '#DF1A30';

export const IMPORT_OK = "OK";
export const IMPORT_STANDBY = "STAND_BY";
export const IMPORT_LICENSES = "NOT_ENOUGH_LICENSES";
export const IMPORT_ERROR = "ERROR";

export const IMPORT_ACTIONS_FILTER = [{text: "INSERT", value: "INSERT"}, {text: "UPDATE", value: "UPDATE"}];
