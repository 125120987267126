import * as React from "react";

import * as intl from "../../utils/intl";

import { Modal, Typography, Space, Spin } from "antd";

import { CheckOutlined } from "@ant-design/icons";

import { Redirect, useHistory } from "react-router-dom";
import { useAccount, useImported } from "../../services";
import ImportHeader from "./ImportHeader";
import ImportSummary from "./ImportSummary";
import ImportTable from "./ImportTable";
import ImportFooter from "./ImportFooter";
import { IMPORT_OK } from "../../services/constantes";
import { displayStatus, shouldWait } from "./ImportStatus";

const ImportModal = (props:any) => {
  const history = useHistory();
  
  const { authentified, setCurrentSchool, currentSchool } = useAccount();

  const [uid, setUID] = React.useState<string>();

  const [refreshing, setRefreshing] = React.useState<boolean>(false);  
  const [recall, setRecall] = React.useState<boolean>(false);
  const [aborted, setAborted] = React.useState<boolean>(false);
  const [executing, setExecuting] = React.useState<boolean>(false);
  const [executed, setExecuted] = React.useState<boolean>(false);
  
  const { importedSummary, setImportedSummary, executeImport, clearImport } = useImported(history.location.pathname);

  React.useEffect(() => {
    if(uid) {
      setRefreshing(true);
      if(shouldWait(importedSummary?.status)) {
        setTimeout(() => setRecall(!recall), 500);
      } else {
        setRefreshing(false);
      }
      if (importedSummary?.status !== IMPORT_OK) {
        setTimeout(() => setImportedSummary(uid), 250);
      }
    } else {
      setRefreshing(false);
    }
  }, [recall, uid, importedSummary?.status, setImportedSummary]);

  const handleNewImport = () => {
    setUID(undefined);
    setRefreshing(false);
    setExecuting(false);
    setExecuted(false);
    clearImport();
  }

  const handleAbort = () => {
    props.onAbort(executing || executed);
    setAborted(true);
    handleNewImport();
  }

  const handleImport = () => {
    setExecuting(true);
    executeImport(uid)
    .then(() => {
      setExecuted(true);
      setCurrentSchool(currentSchool.id);
    })
    .finally(() => setExecuting(false));
  };

  const headerProps = {
    executing: executing,
    executed: executed,
    object: props.object,
    new: importedSummary?.new,
    statut: importedSummary?.status,
    fileUploaded: setUID,
    handleNewImport: handleNewImport,
  };

  const footerProps = {
    refreshing: refreshing,
    executing: executing,
    executed: executed,
    object: props.object,
    uid: uid,
    handleAbort: handleAbort,
    handleNewImport: handleNewImport,
    handleImport: handleImport,
  };

  return !authentified ? <Redirect to="/"/> : 
    <Modal closable={false} maskClosable={false} visible={props.visible} centered style={{textAlign: "center"}} width={"75vw"}
        title={<ImportHeader {...headerProps}/>} footer={<ImportFooter {...footerProps}/>}>
      {!executing && !executed && <>
        {importedSummary?.status !== IMPORT_OK ? displayStatus(importedSummary?.status) : <>
          <ImportSummary/>
          <ImportTable object={props.object} uid={uid} aborted={aborted}/>
        </>}
      </>}
      {executing && <>
        <Space>
          <Typography.Title level={3}>{intl.get("import.running")}</Typography.Title>
          <Spin size="default" style={{color: "red"}}/>
        </Space>
      </>}
      {executed && <>
        <Space>
          <Typography.Title level={3} style={{color: "green"}}>{intl.get("import.status.FINISHED")}</Typography.Title>
          <CheckOutlined style={{color: "green", fontSize: 22, lineHeight: 1.5}}/>
        </Space>
      </>}
    </Modal>
};

export default ImportModal;
