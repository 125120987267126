import React from "react";

import * as intl from "../../utils/intl";

import { Select } from "antd";

const LevelSelect = (props:any) => {
  
    return <>
        <Select {...props} allowClear style={{width: "100%"}} onClear={props.onChange} onSelect={props.onChange} value={props.value}>
        {[...Array(18)].map((e, i) =>
            <Select.Option key={i+1} value={i+1}>{intl.get(`levels.LEVEL_${i+1}`)}</Select.Option>
        )}
        </Select>
    </>;
  }

  export default LevelSelect;