import * as React from "react";
import { Switch, Route, Redirect } from "react-router";

import SecureRoute from "./components/SecureRoute";
import LireProvider from "./utils/LireProvider";

import Login from "./pages/Login";
import Global from "./pages/Global";
import InSchool from "./pages/InSchool";
import Statistics from "./pages/Statistics";

import * as intl from "react-intl-universal";

import "../src/style/lire.css";
import { useState } from "react";

const locales = {
  "en-US": require('../src/locales/en-US.json'),
  "fr-FR": require('../src/locales/fr-FR.json'),
};

function App() {
  const [initDone, setInitDone] = useState<boolean>(false);
  const [currentLocale, setCurrentLocale] = useState(window.localStorage.getItem("locale") || "fr-FR");

  const loadLocal = (local:string) => {
    intl.init({ currentLocale : local, locales })
    .then(() => setCurrentLocale(local));
  }
  
  React.useEffect(() => {
    window.localStorage.setItem("locale", currentLocale);
  }, [currentLocale]);
  
  React.useEffect(() => {
    if (!initDone) {
      intl.init({ currentLocale, locales })
      .then(() => setInitDone(true));
    }
  }, [currentLocale, initDone]);

  return !initDone ? <></> : (
    <LireProvider>
      <Switch>
        <SecureRoute path="/account/admins/root" loginPath="/login" children={<Global/>}/>
        <SecureRoute path="/schools" loginPath="/login" children={<Global/>}/>
        <SecureRoute path="/account/admins" loginPath="/login" children={<InSchool/>}/>
        <SecureRoute path="/account/teachers" loginPath="/login" children={<InSchool/>}/>
        <SecureRoute path="/classrooms" loginPath="/login" children={<InSchool/>}/>
        <SecureRoute path="/account/students" loginPath="/login" children={<InSchool/>}/>
        <SecureRoute path="/statistics/books" loginPath="/login" children={<Statistics/>}/>
        <Route path="/login" children={<Login setCurrentLocale={loadLocal}/>}/>
        <Route path="/" children={<Redirect to="/login" />}/>
      </Switch>
    </LireProvider>);
}

export default App;
