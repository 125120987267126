import * as intl from "react-intl-universal";

export const getGroup = (group:string) => {
    return intl.get(group);
}

export const get = (key:string, defaultMessage?:string, params?:any) => {
    const message = intl.get(key, params);
    return defaultMessage ? message.d(defaultMessage) : message;
}

export const getHTML = (key:string, defaultMessage?:string, params?:any) => {
    const message = intl.getHTML(key, params);
    return defaultMessage ? message.d(defaultMessage) : message;
}
