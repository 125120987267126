import {reducer as session, State as SessionState}  from './services/reducers';

const rootReducer = {
    session
};

export interface State {
    session: SessionState;
}

export default rootReducer;