import React from "react";

import * as intl from "../../utils/intl";

import { Card, Input, Table, TablePaginationConfig } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useImported } from "../../services";
import { InfosModel, initialInfos } from "../../services/models";
import { getImportColumns, getImportErrorsFilter, getRenderedCell } from "../../components/columns";
import { IMPORT_ACTIONS_FILTER, IMPORT_OK } from "../../services/constantes";

const ImportTable = (props:any) => {
    const history = useHistory();

    const { importedSummary, importedList, importedTotal, setImportedList } = useImported(history.location.pathname);
    const [infos, setInfos] = React.useState<InfosModel>(initialInfos);

    const [listed, setListed] = React.useState<boolean>(false);  

    const updateInfos = (pagination:TablePaginationConfig, filter:any, sorter:any) => {
        let info:InfosModel = {...infos,
            ...(filter.action ? {action: filter.action.join(",")} : {action: undefined}),
            ...(filter.msgCode ? {msgCode: filter.msgCode.join(",")} : {msgCode: undefined}),
            page: pagination.current ? pagination.current : 1,
            results: pagination.pageSize ? pagination.pageSize : 10,
            sortField: sorter.order ? sorter.field : undefined,
            sortOrder: sorter.order ? sorter.order : undefined,
        }
        setInfos(info);
    }
    
    const handleSearch = (searched:string) => {
        setInfos({...infos, page: 1, search: searched});
    }

    const columns = () => {
        return [
            {
                title: "Action", dataIndex: "action", key: "action", width: "8%",
                filterMultiple: false, filters: IMPORT_ACTIONS_FILTER, 
            },
            ...getImportColumns(props.object),
            {
                title: intl.get("import.column_error"), dataIndex: "msgCode", key: "msgCode", width: "22%",
                filterMultiple: false, filters: getImportErrorsFilter(importedSummary?.messages || []),
                render: (text:any, record:any) => getRenderedCell(intl.get(`problems.${text}`, text), record.state),
            },
        ].map(column => ({...column, ellipsis: true}));
    }
    
    React.useEffect(() => {
        if(props.aborted) {
            setInfos(initialInfos);
        }
    }, [props.aborted]);

    React.useEffect(() => {
        if(importedSummary?.status === IMPORT_OK) {
            setListed(false);
            setImportedList(props.uid, infos).then(() => setListed(true));
        }
    }, [importedSummary?.status, infos, props.uid, setImportedList]);

    return listed && importedList?.length > 0 ? <>
        <Card hidden={importedSummary?.status !== IMPORT_OK} 
                title={<Input.Search placeholder={intl.get("labels.search")} prefix={<SearchOutlined />} onSearch={handleSearch} allowClear/>}>
            <Table loading={!listed}
                showSorterTooltip={false}
                style={{height: 394}}
                onChange={updateInfos}
                rowKey={record => record.id}
                pagination={{ position: ['topCenter'], showSizeChanger: false, pageSize: 5, total: importedTotal, current:infos.page }}
                dataSource={listed ? importedList : []}
                columns={columns()}/>
        </Card>
    </> : <></>;
};

export default ImportTable;