import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {State} from '../rootReducer';

import * as AccountActions from './actions/accountActions';
import * as ListActions from './actions/listActions';
import * as EditActions from './actions/editActions';
import * as ImportExportActions from './actions/importExportActions';

import * as Selectors from "./selectors";

import {AccountModel, ClassroomModel, ImportModel, InfosModel, SchoolModel, StudentModel, TeacherModel, TokenModel} from './models';
import { AxiosRequestConfig } from 'axios';

export const useAccount = () => {
    const loaded:boolean = useSelector((state:State) => Selectors.isLoaded(state));
    const authentified:boolean = useSelector((state:State) => Selectors.isAuthentified(state));

    const token:TokenModel = useSelector((state:State) => Selectors.getToken(state));
    const root:boolean = useSelector((state:State) => Selectors.isRoot(state));

    const account:AccountModel = useSelector((state:State) => Selectors.getAccount(state));
    const currentSchool:SchoolModel = useSelector((state:State) => Selectors.getCurrentSchool(state));
    
    const admins:Array<AccountModel> = useSelector((state:State) => Selectors.getAdmins(state));
    const schools:Array<SchoolModel> = useSelector((state:State) => Selectors.getSchools(state));
    const classes:Array<ClassroomModel> = useSelector((state:State) => Selectors.getClasses(state));
    const teachers:Array<TeacherModel> = useSelector((state:State) => Selectors.getTeachers(state));

    const dispatch = useDispatch();

    const getToken = React.useCallback((values:any) => AccountActions.doGetToken(dispatch, values), [dispatch]);
    const setCurrentSchool = React.useCallback((school?:number|string) => AccountActions.doSetCurrentSchool(dispatch, token, school), [dispatch, token]);
    const login = React.useCallback(() => AccountActions.doLogin(dispatch, token), [dispatch, token]);
    const logout = React.useCallback(() => AccountActions.doLogout(dispatch), [dispatch]);

    const loadAdmins = React.useCallback((school:string|number) => ListActions.doLoadAdmins(dispatch, token, school), [dispatch, token]);
    const loadSchools = React.useCallback(() => ListActions.doLoadSchools(dispatch, token), [dispatch, token]);
    const loadClasses = React.useCallback(() => ListActions.doLoadClasses(dispatch, token, currentSchool), [dispatch, token, currentSchool]);
    const loadTeachers = React.useCallback(() => ListActions.doLoadTeachers(dispatch, token, currentSchool), [dispatch, token, currentSchool]);

    return {
        loaded, authentified, token, root, account, currentSchool, 
        getToken, setCurrentSchool, login, logout,
        admins, schools, classes, teachers, 
        loadAdmins, loadSchools, loadClasses, loadTeachers, 
    };
}

export const useList = (route:string, root:boolean = false) => {
    const token:TokenModel = useSelector((state:State) => Selectors.getToken(state));
    const currentSchool:SchoolModel = useSelector((state:State) => Selectors.getCurrentSchool(state));

    const header:AxiosRequestConfig = useSelector((state:State) => Selectors.getHeader(state));

    const dispatch = useDispatch();
    
    const list = React.useCallback((info:InfosModel) => ListActions.doList(dispatch, route, info, token, root, currentSchool), [dispatch, route, token, root, currentSchool]);
    
    const massDelete = React.useCallback((ids:any[]) => ListActions.doMassDelete(dispatch, route, ids, header), [dispatch, route, header]);
    const loadExportable = React.useCallback((infos:InfosModel) => ImportExportActions.doExportable(route, infos, header), [route, header]);

    return { list, massDelete, loadExportable };
}

export const useImported = (path:string) => {
    let route:string = `${path.replace("/root", "")}/import`;
    let root:boolean = path.indexOf("/root") > -1;

    const header:AxiosRequestConfig = useSelector((state:State) => Selectors.getHeader(state));

    const importedSummary:ImportModel = useSelector((state:State) => Selectors.getImportedSummary(state));
    const importedList:Array<any> = useSelector((state:State) => Selectors.getImportedList(state));
    const importedTotal:number = useSelector((state:State) => Selectors.getImportedTotal(state));
    
    const dispatch = useDispatch();
    
    const importUpload = React.useCallback((uid:string, data:FormData) => ImportExportActions.doImportUpload(dispatch, `${route}/${uid}`, data, header, root), [dispatch, route, header, root]);
    const setImportedSummary = React.useCallback((uid?:string) => ImportExportActions.doImportedSummary(dispatch, `${route}/${uid}/infos`, header), [dispatch, route, header]);
    const setImportedList = React.useCallback((uid:string, infos:InfosModel) => ImportExportActions.doImportedList(dispatch, `${route}/${uid}/list`, infos, header), [dispatch, route, header]);
    const loadImportedExported = React.useCallback((uid:string, infos:InfosModel) => ImportExportActions.doImportedExported(`${route}/${uid}/list`, infos, header), [route, header]);
    
    const executeImport = React.useCallback((uid?:string) => ImportExportActions.doExecuteImport(`${route}/${uid}/go`, header, root), [route, header, root]);
    const clearImport = React.useCallback(() => ImportExportActions.doClearImport(dispatch), [dispatch]);

    return {
        importedSummary, importedList, importedTotal, 
        importUpload, setImportedList, setImportedSummary, loadImportedExported, executeImport, clearImport
    };
}

export const useEdited = () => {
    const header:AxiosRequestConfig = useSelector((state:State) => Selectors.getHeader(state));

    const loadAdmin = React.useCallback((id:any) => EditActions.doLoadAdmin(id, header), [header]);
    const loadSchool = React.useCallback((id:any) => EditActions.doLoadSchool(id, header), [header]);
    const loadClassroom = React.useCallback((id:any) => EditActions.doLoadClassroom(id, header), [header]);
    const loadTeacher = React.useCallback((id:any) => EditActions.doLoadTeacher(id, header), [header]);
    const loadStudent = React.useCallback((id:any) => EditActions.doLoadStudent(id, header), [header]);

    const deleteAdmin = React.useCallback((id:any) => EditActions.doDeleteAdmin(id, header), [header]);
    const deleteSchool = React.useCallback((id:any) => EditActions.doDeleteSchool(id, header), [header]);
    const deleteClassroom = React.useCallback((id:any) => EditActions.doDeleteClassroom(id, header), [header]);
    const deleteTeacher = React.useCallback((id:any) => EditActions.doDeleteTeacher(id, header), [header]);
    const deleteStudent = React.useCallback((id:any) => EditActions.doDeleteStudent(id, header), [header]);

    const saveAdmin = React.useCallback((admin:AccountModel, root:boolean = false) => EditActions.doSaveAdmin(admin, root, header), [header]);
    const saveSchool = React.useCallback((school:SchoolModel) => EditActions.doSaveSchool(school, header), [header]);
    const saveClassroom = React.useCallback((classroom:ClassroomModel) => EditActions.doSaveClassroom(classroom, header), [header]);
    const saveTeacher = React.useCallback((teacher:TeacherModel) => EditActions.doSaveTeacher(teacher, header), [header]);
    const saveStudent = React.useCallback((student:StudentModel) => EditActions.doSaveStudent(student, header), [header]);
    
    return {
        loadAdmin, loadSchool, loadClassroom, loadTeacher, loadStudent, 
        deleteAdmin, deleteSchool, deleteClassroom, deleteTeacher, deleteStudent, 
        saveAdmin, saveSchool, saveClassroom, saveTeacher, saveStudent,
    };
}
