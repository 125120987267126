import * as React from "react";

import { Switch, Route } from "react-router";
import Layout from "../../components/Layout";

import Table from "./Table";

const Statistics = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/statistics/books" component={Table}/>
      </Switch>
    </Layout>
  );
};

export default Statistics;
