import * as React from "react";

import * as intl from "../../utils/intl";

import { Row, Col, Card, Space } from "antd";
import { useHistory } from "react-router";
import { useImported } from "../../services";
import { IMPORT_OK } from "../../services/constantes";

const ImportSummary = () => {

  const history = useHistory();

  const { importedSummary } = useImported(history.location.pathname);

  return <>
    <Card hidden={importedSummary?.status !== IMPORT_OK} title={intl.get("import.summary")} style={{ borderBottom: "none" }}>
      <Row gutter={24}>
        <Col span={4}>
          <Space size="small" split=":" style={{fontWeight: "bold"}}>
            {intl.get("import.total")}
            {importedSummary?.total || intl.getHTML("import.none")}
          </Space>
        </Col>
        <Col offset={4} span={3}>
          <Space size="small" split=":" wrap>
            {intl.get("import.warnings")}
            {importedSummary?.warnings || intl.getHTML("import.none")}
          </Space>
        </Col>
        <Col span={3}>
          <Space size="small" split=":" wrap>
            {intl.get("import.errors")}
            {importedSummary?.errors || intl.getHTML("import.none")}
          </Space>
        </Col>
        <Col offset={2} span={3}>
          <Space size="small" split=":" wrap>
            {intl.get("import.insert")}
            {importedSummary?.insert || intl.getHTML("import.none")}
          </Space>
        </Col>
        <Col span={3}>
          <Space size="small" split=":" wrap>
            {intl.get("import.update")}
            {importedSummary?.update || intl.getHTML("import.none")}
          </Space>
        </Col>
      </Row>
    </Card>
  </>;
}

export default ImportSummary;