import React from "react";

import * as intl from "../../utils/intl";

// import { CheckOutlined } from "@ant-design/icons";

import { Button, Space } from "antd";
import { useImported } from "../../services";
import { useHistory } from "react-router";
import { initialInfos } from "../../services/models";
import { CSVLink } from "react-csv";
import { getHeaders, getExport } from "../../components/columns";
import { IMPORT_OK } from "../../services/constantes";

const ImportFooter = (props: any) => {
  const history = useHistory();

  const csvlink = React.useRef<any | null>(null);

  const { importedSummary, loadImportedExported } = useImported(history.location.pathname);

  const [ready, setReady] = React.useState<boolean>(false);

  const [exporting, setExporting] = React.useState<boolean>(false);
  const [csv, setCSV] = React.useState<any[]>([]);

  const handleAbort = () => {
    setReady(false);
    props.handleAbort();
  }

  const handleExport = async () => {
    setExporting(true);
    loadImportedExported(props.uid, { ...initialInfos, results: importedSummary.total })
    .then(data => setCSV(getExport(data, props.object)));
  }

  React.useEffect(() => {
    if (importedSummary?.status === IMPORT_OK) {
      console.warn('setReady(true)');
      setReady(true);
    } else {
      console.warn('setReady(false)');
      setReady(false);
    }
  }, [importedSummary]);

  React.useEffect(() => {
    if (csv && csvlink.current && csvlink.current.link) {
      setTimeout(() => {
        csvlink.current.link.click();
        setCSV([]);
        setExporting(false);
      });
    }
  }, [csv]);

  const headers = getHeaders(props.object, true);

  console.warn('RENDER');
  return <>
    <Space>
      <Button key="abort" onClick={handleAbort}>{intl.get(props.executed ? "import.terminate" : "import.abort")}</Button>
      {ready && <>
        {!props.executing && !props.executed && <>
          <Button key="export" onClick={handleExport} type="ghost" danger loading={exporting}
            disabled={importedSummary.errors + importedSummary.warnings === 0}>
            {intl.get("import.export")}
          </Button>
          <Button key="import" loading={props.executing} onClick={props.handleImport} type="primary"
            disabled={importedSummary.total === importedSummary.errors}>
            {intl.get("import.import")}
          </Button>
        </>}
      </>}
    </Space>
    {csv.length > 0 && <CSVLink data={csv} headers={headers} separator=";" hidden ref={csvlink} filename={`${props.object}_import_${props.uid}_${new Date().toLocaleString()}.csv`} />}
  </>;
};

export default ImportFooter;