import styled from "@emotion/styled";
import {  Radio } from "antd";

const FlagRadio = styled(Radio.Button)`
  width: 36px;
  height: 24px;
  padding: 0;
  margin-right: 6px;
`;

const FlagImage = styled.img`
  border: 1px solid white;
  height: 16px;
  width: 26px;
  position: relative;
  top: -6px;
  left: 4px;
`;

const Flags = (props:any) => {
    return <>
      <Radio.Group optionType="button" buttonStyle="solid" onChange={e => {props.onSelect(e.target.value)}} defaultValue={window.localStorage.getItem("locale") || "fr-FR"}>
        <FlagRadio value="fr-FR">
          <FlagImage src={"images/fra.jpg"}/>
        </FlagRadio>
        <FlagRadio value="en-US">
          <FlagImage src={"images/eng.jpg"}/>
        </FlagRadio>
      </Radio.Group>
    </>;
  }

  export default Flags;