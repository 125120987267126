import * as React from "react";

import { Switch, Route, Redirect } from "react-router";
import Layout from "../../components/Layout";

import SchoolAdminTable from "./SchoolAdminTable";
import SchoolAdminForm from "./SchoolAdminForm";
import ClassroomTable from "./ClassroomTable";
import ClassroomForm from "./ClassroomForm";
import TeacherTable from "./TeacherTable";
import TeacherForm from "./TeacherForm";
import StudentTable from "./StudentTable";
import StudentForm from "./StudentForm";
import { useAccount } from "../../services";

const InSchool = () => {
  
  const { currentSchool } = useAccount();
  
  return currentSchool?.removed ? <Redirect to="/account/admins/root" /> : (
    <Layout>
      <Switch>
        <Route exact path="/account/admins" children={<SchoolAdminTable/>}/>
        <Route exact path="/account/admins/new" children={<SchoolAdminForm/>}/>
        <Route exact path="/account/admins/edit/:id" children={<SchoolAdminForm/>}/>

        <Route exact path="/classrooms" children={<ClassroomTable/>}/>
        <Route exact path="/classrooms/new" children={<ClassroomForm/>}/>
        <Route exact path="/classrooms/edit/:id" children={<ClassroomForm/>}/>

        <Route exact path="/account/teachers" children={<TeacherTable/>}/>
        <Route exact path="/account/teachers/new" children={<TeacherForm/>}/>
        <Route exact path="/account/teachers/edit/:id" children={<TeacherForm/>}/>

        <Route exact path="/account/students" children={<StudentTable/>}/>
        <Route exact path="/account/students/new" children={<StudentForm/>}/>
        <Route exact path="/account/students/edit/:id" children={<StudentForm/>}/>
      </Switch>
    </Layout>
  );
};

export default InSchool;
