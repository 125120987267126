import React from "react";

import * as intl from "../utils/intl";

import { RiseOutlined, FallOutlined } from "@ant-design/icons";

export const getExport = (exportable:any[] = [], object:string) => {
  return exportable.map(e => ({action: "UPDATE", ...e}));
}

export const getHeaders = (object:string, template:boolean = false) => {
  switch(object) {
      case "admins":
          return adminsHeaders(template);
      case "schools":
          return schoolsHeaders(template);
      case "classrooms":
          return classroomsHeaders(template);
      case "teachers":
          return teachersHeaders(template);
      case "students":
          return studentsHeaders(template);
      case "statistics":
          return statisticsHeaders(template);
  }
}

export const getColumns = (object:string) => {
  switch(object) {
      case "admins":
          return adminsColumns();
      case "schools":
          return schoolsColumns();
      case "classrooms":
          return classroomsColumns();
      case "teachers":
          return teachersColumns();
      case "students":
          return studentsColumns();
      case "statistics":
          return statisticsColumns();
  }
}

const importColumn = (text:any, rec:any, column:string, display?:any) => {
  let value = display || text || "?";
  if(column.indexOf(rec.columnError) > -1) {
    return getRenderedCell(value, rec.state);
  } else {
    return value;
  }
}

export const getImportErrorsFilter = (messages:any[]) => {
  return Object.entries(intl.getGroup("problems")).filter((k:any[]) => messages.indexOf(k[0]) > -1).map((k:any[]) => {return {text: k[1], value: k[0]}});
}

export const getRenderedCell = (value:string, state:string) => {
  return <span style={{padding: 4}} className={`ant-typography ant-typography-${state === "ERROR" ? "danger ant-alert-error" : "warning ant-alert-warning"}`}>{value}</span>;
}

export const getImportColumns = (object:string) => {
  switch(object) {
    case "admins":
        return adminsImportColumns();
    case "schools":
        return schoolsImportColumns();
    case "classrooms":
      return classroomsImportColumns();
    case "teachers":
      return teachersImportColumns();
    case "students":
        return studentsImportColumns();
  }
  return [];
}

const adminsHeaders = (template:boolean) => {
  return [
    {label: "Action", key: "action"},
    {label: "Login", key: "login"},
    {label: "FirstName", key: "firstName"},
    {label: "LastName", key: "lastName"},
    {label: "Email", key: "email"},
    {label: "Password", key: "password"},
  ]
};

const adminsColumns = () => {
  return [
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", width: '30%', sorter: true,
      render: (text:any, rec:any) => `${text}${rec.isMain ? " (principal)" : ""}`,
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", width: '35%', sorter: true,
      render: (text:any, rec:any) => `${rec.lastName} ${rec.firstName}`,
    },
    {
      title: intl.get("labels.columns.email"), dataIndex: "email", key: "email", width: '35%', sorter: true,
    },
  ];
}

const adminsImportColumns = () => {
  return [
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "login"),
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "lastName, firstName", `${rec.lastName} ${rec.firstName}`),
    },
    {
      title: intl.get("labels.columns.email"), dataIndex: "email", key: "email", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "email"),
    },
  ];
}

const schoolsHeaders = (template:boolean) => {
  return [
    {label: "Action", key: "action"},
    {label: "Name", key: "name"},
    {label: "NbLicenses", key: template ? "nbLicenses" : "licenses"},
    {label: "Login", key: "admin.login"},
    {label: "FirstName", key: "admin.firstName"},
    {label: "LastName", key: "admin.lastName"},
    {label: "Email", key: "admin.email"},
    {label: "Password", key: "admin.password"},
  ]
};

const schoolsColumns = () => {
  return [
    {
      title: intl.get("labels.columns.name"), dataIndex: "name", key: "name", width: '30%', sorter: true,
    },
    {
      title: intl.get("labels.columns.licenses"), dataIndex: "licenses", key: "licenses", width: '20%', sorter: true,
    },
    {
      title: intl.get("labels.columns.licenses_used"), dataIndex: "licensesUsed", key: "licensesUsed", width: '20%', sorter: false,
    },
    {
      title: intl.get("labels.columns.admin"), dataIndex: "admin.lastName, admin.firstName", key: "admin", width: '30%', sorter: true,
	    render: (text:any, rec:any) => rec.admin ? `${rec.admin.lastName} ${rec.admin.firstName}` : ``,
    },
  ];
}

const schoolsImportColumns = () => {
  return [
    {
      title: intl.get("labels.columns.name"), dataIndex: "name", key: "name", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "name"),
    },
    {
      title: intl.get("labels.columns.licenses"), dataIndex: "nbLicenses", key: "nbLicenses", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "licenses"),
    },
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", sorter: true,
  	  render: (text:any, rec:any) => importColumn(text, rec, "login"),
    },
    {
      title: intl.get("labels.columns.admin"), dataIndex: "lastName, firstName", key: "lastName", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "lastName, firstName", `${rec.lastName || "?"} ${rec.firstName || "?"}`),
    },
    {
      title: intl.get("labels.columns.email"), dataIndex: "email", key: "email", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "email"),
    },
    {
      title: intl.get("labels.columns.password"), dataIndex: "password", key: "password",
      render: (text:any, rec:any) => importColumn(text, rec, "password", "*****"),
    },
  ];
}

const classroomsHeaders = (template:boolean) => {
  return [
    {label: "Action", key: "action"},
    {label: "Name", key: "name"},
    {label: "ClassLevel", key: template ? "classLevel" : "defaultLevel"},
    {label: "TeacherLogin", key: "teacher.login"}
  ]
};

const classroomsColumns = () => {
  return [
    {
      title: intl.get("labels.columns.class_name"), dataIndex: "name", key: "name", width: '30%', sorter: true,
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "teacher.lastName, teacher.firstName", key: "lastName", width: '30%', sorter: true,
      render: (text:any, rec:any) => rec.teacher ? `${rec.teacher.lastName} ${rec.teacher.firstName}` : "-",
    },
    {
      title: intl.get("labels.columns.students"), dataIndex: "studentsCount", key: "studentsCount", width: '15%', sorter: false,
    },
    {
      title: intl.get("labels.columns.level"), dataIndex: "defaultLevel", key: "defaultLevel", width: '25%', sorter: true,
      render: (text:any, rec:any) => text && intl.get(`levels.LEVEL_${rec.defaultLevel}`, `Niveau ${rec.defaultLevel}`),
    },
  ];
}

const classroomsImportColumns = () => {
  return [
    {
      title: intl.get("labels.columns.class_name"), dataIndex: "name", key: "name", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "name"),
    },
    {
      title: intl.get("labels.columns.teacher_login"), dataIndex: "teacherLogin", key: "teacherLogin", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "teacherLogin"),
    },
    {
      title: intl.get("labels.columns.class_level"), dataIndex: "classLevel", key: "classLevel", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "classLevel", intl.get(`classes.LEVEL_${rec.classLevel}`, `Niveau ${rec.classLevel}`)),
    },
  ];
}

const teachersHeaders = (template:boolean) => {
  return [
    {label: "Action", key: "action"},
    {label: "Login", key: "login"},
    {label: "Email", key: "email"},
    {label: "FirstName", key: "firstName"},
    {label: "LastName", key: "lastName"},
    {label: "Password", key: "password"}
  ]
};
  
const teachersColumns = () => {
  return [
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", width: '20%', sorter: true,
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", width: '30%', sorter: true,
      render: (text:any, rec:any) => `${rec.lastName} ${rec.firstName}`,
    },
    {
      title: intl.get("labels.columns.classes"), dataIndex: "classes", key: "classes", width: '50%', sorter: false,
      render: (text:any, rec:any) => rec.classRooms?.map((c:any) => c.name).join(", "),
    },
  ];
}

const teachersImportColumns = () => {
  return [
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "login"),
    },
    {
      title: intl.get("labels.columns.password"), dataIndex: "password", key: "password",
      render: (text:any, rec:any) => importColumn(text, rec, "password", "*****"),
    },
    {
      title: intl.get("labels.columns.email"), dataIndex: "email", key: "email", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "email"),
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "lastName, firstName", `${rec.lastName || "?"} ${rec.firstName || "?"}`),
    },
    {
      title: intl.get("labels.columns.classes"), dataIndex: "classes", key: "classes", sorter: false,
      render: (text:any, rec:any) => importColumn(text, rec, "classes", rec.classRooms?.map((c:any) => c.name).join(", ")),
    },
  ];
}

const studentsHeaders = (template:boolean) => {
  return [
    {label: "Action", key: "action"},
    {label: "Login", key: "login"},
    {label: "FirstName", key: "firstName"},
    {label: "LastName", key: "lastName"},
    {label: "ClassName", key: template ? "className" : "classroomName"},
    {label: "Level", key: template ? "level" : "currentLevel"},
    {label: "Email", key: "email"},
    {label: "Password", key: "password"},
  ]
};

const studentsColumns = () => {
  return [
    {
        title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", width: '10%', sorter: true,
    },
    {
        title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", width: '30%', sorter: true,
        render: (text:any, rec:any) => `${rec.lastName} ${rec.firstName}`,
    },
    {
        title: intl.get("labels.columns.classroom"), dataIndex: "classroomName", key: "classroomName", width: '30%', sorter: true,
    },
    {
        title: intl.get("labels.columns.level"), dataIndex: "currentLevel", key: "currentLevel", width: '30%', sorter: true,
        render: (text:any, rec:any) => text && intl.get(`levels.LEVEL_${rec.currentLevel}`, `Niveau ${rec.currentLevel}`),
    },
  ];
}

const studentsImportColumns = () => {
  return [
    {
      title: intl.get("labels.columns.login"), dataIndex: "login", key: "login", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "login"),
    },
    {
      title: intl.get("labels.columns.fullname"), dataIndex: "lastName, firstName", key: "lastName", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "lastName, firstName", `${rec.lastName || "?"} ${rec.firstName || "?"}`),
    },
    {
      title: intl.get("labels.columns.classroom"), dataIndex: "className", key: "className", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "className"),
    },
    {
      title: intl.get("labels.columns.level"), dataIndex: "level", key: "level", sorter: true,
      render: (text:any, rec:any) => importColumn(text, rec, "level", intl.get(`levels.LEVEL_${rec.level}`)),
    }
  ];
}

const statisticsHeaders = (template:boolean) => {
  return [
    {label: "Rank", key: "rank"},
    {label: "BookName", key: "name"},
    {label: "Days", key: "days"},
    {label: "UniqueRead", key: "uniqueReadCounter"},
    {label: "MonthRead", key: "monthReadCounter"},
  ]
};

const statisticsColumns = () => {
  return [
    {
      title: intl.get("labels.columns.rank"), dataIndex: "rank", key: "rank", width: '10%', sorter: true,
    },
    {
      title: intl.get("labels.columns.book_name"), dataIndex: "name", key: "name", width: '33%', sorter: true,
    },
    {
      title: intl.get("labels.columns.since"), dataIndex: "days", key: "days", width: '15%', sorter: true,
      render: (text:any, rec:any) => `${rec.days} jour${rec.days > 1 ? 's' : ''}`,
    },
    {
      title: intl.get("labels.columns.read_count"), dataIndex: "uniqueReadCounter", key: "uniqueReadCounter", width: '12%', sorter: true,
    },
    {
      title: intl.get("labels.columns.read_month"), dataIndex: "monthReadCounter", key: "monthReadCounter", width: '15%', sorter: true,
      render: (text:any, rec:any) => {
        return <>{rec.monthReadCounter} {(rec.progression === 1 ? <RiseOutlined/> : (rec.progression === -1 ? <FallOutlined/> : <></>))}</>
      },
    },
  ];
}
