import React from "react";

import * as intl from "../../utils/intl";

import { Form, Row, Col, Input, Button, Card, Divider, Skeleton, Modal } from "antd";
import { InfoCircleOutlined, ExclamationCircleTwoTone, WarningTwoTone, ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useAccount, useEdited } from "../../services";
import { AccountModel, initialAdmin } from "../../services/models";

const layout = {
  labelCol: { offset: 2, span: 10 },
  wrapperCol: { offset: 2, span: 20 },
};

interface Params {
  id:string
}

const SchoolAdminForm = () => {
  const history = useHistory();

  const params = useParams<Params>();

  const mode = params?.id ? "edit" : "create";

  const { account } = useAccount();

  const { loadAdmin, deleteAdmin, saveAdmin } = useEdited();

  const [current, setCurrent] = React.useState<AccountModel>(initialAdmin);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
  const [success, setSuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    const ac = new AbortController();
    if(params.id) {
      loadAdmin(params.id)
      .then(setCurrent)
      .catch(setError)
      .finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
    return () => ac.abort();
  }, [ loadAdmin, params.id]);
  
  React.useEffect(() => {
    const ac = new AbortController();
    if(deleting) {
      setError("");
      deleteAdmin(params.id)
      .then(setSuccess)
      .catch(setError)
      .finally(() => setDeleting(false));
    } else if (saving) {
      setError("");
      saveAdmin(current)
      .then(setSuccess)
      .catch(setError)
      .finally(() => setSaving(false));
    }
    return () => ac.abort();
  }, [current, deleteAdmin, deleting, params.id, saveAdmin, saving]);
  
  const onFinish = (values:AccountModel) => {
    setCurrent(values);
    setSaving(true);
  };
  
  const handleDelete = () => {
    setAskDelete(false);
    setDeleting(true);
  };

  const getRules = (rule: string) => {
    if (rule === "password") {
        return [{ required: mode === "create", message: intl.get("form.rules.required") }];
    }
    return [{ required: true, message: intl.get("form.rules.required") }];
  };

  const getPasswordHelp = () => {
    if (error && error.response.data === "INVALID_PASSWORD") {
      return intl.get("form.rules.password_admin", error.response.data);
    }
    if (mode === "edit") {
      return intl.get("form.rules.login_edit");
    }
    return "";
  }

  return !loaded ? <Skeleton/> : (success ? <Redirect push to={history.location.state ? String(history.location.state) : "/account/admins"}/> : <>
      <Card title={intl.get(`form.title.${mode}_admins`)} bordered
          extra={error && 
            <span style={{fontSize: 16, color: "red"}}><WarningTwoTone twoToneColor={"orange"} style={{marginRight: 12}}/>
              {(error.response && intl.get(`problems.${error.response.data}`, `Une erreur est survenue: ${error.response.data}`)) ||
              (error.message && `Une erreur est survenue: ${error.message}`)}
            </span>}>
        <Form {...layout} layout="vertical" initialValues={current} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="lastName" label={intl.get("labels.columns.name")} rules={getRules("lastName")}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="firstName" label={intl.get("labels.columns.firstname")} rules={getRules("firstName")}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="login" label={intl.get("labels.columns.login")} rules={getRules("login")}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="password" label={intl.get("labels.columns.password")} rules={getRules("password")} help={getPasswordHelp()}
                  tooltip={{ title: intl.get("form.rules.password_admin"), icon: <InfoCircleOutlined /> }}>
                <Input.Password autoComplete="new-password" allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label={intl.get("labels.columns.email")} rules={getRules("email")}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            {current.id !== undefined && <Col span={12} hidden>
              <Form.Item name="id" label="ID">
                <Input readOnly/>
              </Form.Item>
            </Col>}
          </Row>
          <Divider/>
          <Form.Item style={{marginBottom: 0}}>
            <Row style={{ textAlign: "center" }}>
              <Col span={8}>
                <Button onClick={() => setSuccess(true)} icon={<ArrowLeftOutlined/>}>{intl.get("labels.back")}</Button>
              </Col>
              <Col span={8}>
                {mode === "edit" && current.id !== account.id && !current.isMain && <>
                  <Button loading={deleting} onClick={() => setAskDelete(true)} danger icon={<DeleteOutlined/>}>{intl.get("labels.delete")}</Button>
                  <Modal title={<ExclamationCircleTwoTone twoToneColor="red"/>} visible={askDelete} 
                      cancelText={intl.get("buttons.cancel")} okText={intl.get("buttons.valid")}
                      onOk={handleDelete} onCancel={() => setAskDelete(false)}>
                    <p>{intl.get("messages.sure")}</p>
                  </Modal>
                </>}
              </Col>
              <Col span={8}>
                <Button loading={saving} htmlType="submit" type="primary" icon={<SaveOutlined/>}>{intl.get("labels.save")}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default SchoolAdminForm;
