import React from "react";

import * as intl from "../../utils/intl";

import { Form, Row, Col, Input, Button, Card, Divider, Skeleton, Select, Modal } from "antd";
import { ExclamationCircleTwoTone, PlusCircleTwoTone, WarningTwoTone, ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useAccount, useEdited } from "../../services";
import { ClassroomModel, initialClassroom } from "../../services/models";
import LevelSelect from "../../components/Layout/LevelSelect";

const layout = {
  labelCol: { offset: 2, span: 10 },
  wrapperCol: { offset: 2, span: 20 },
};

interface Params {
  id:string
}

const ClassroomForm = () => {
  const history = useHistory();

  const params = useParams<Params>();

  const mode = params?.id ? "edit" : "create";

  const { teachers, loadTeachers } = useAccount();
  const { loadClassroom, deleteClassroom, saveClassroom } = useEdited();

  const [current, setCurrent] = React.useState<ClassroomModel>(initialClassroom);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
  const [success, setSuccess] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    const ac = new AbortController();
    loadTeachers();
    return () => ac.abort();
  }, [loadTeachers]);

  React.useEffect(() => {
    const ac = new AbortController();
    if(params.id) {
      loadClassroom(params.id)
      .then(setCurrent)
      .catch(setError)
      .finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
    return () => ac.abort();
  }, [ loadClassroom, params.id]);
  
  React.useEffect(() => {
    const ac = new AbortController();
    if(deleting) {
      setError("");
      deleteClassroom(params.id)
      .then(setSuccess)
      .catch(setError)
      .finally(() => setDeleting(false));
    } else if (saving) {
      setError("");
      saveClassroom(current)
      .then(setSuccess)
      .catch(setError)
      .finally(() => setSaving(false));
    }
    return () => ac.abort();
  }, [current, deleteClassroom, deleting, params.id, saveClassroom, saving]);
  
  const onFinish = (values:ClassroomModel) => {
    setCurrent(values);
    setSaving(true);
  }
  
  const handleDelete = () => {
    setAskDelete(false);
    setDeleting(true);
  };

  const rules = {
    name: [{ required: true, message: intl.get("form.rules.required") }],
    description: [{ required: false, message: intl.get("form.rules.required") }],
    defaultLevel: [{ required: true, message: intl.get("form.rules.required") }],
    teacher: [{ required: true, message: intl.get("form.rules.required") }],
  };
  
  return !loaded ? <Skeleton/> : (success ? <Redirect push to={history.location.state ? String(history.location.state) : "/classrooms"}/> : <>
      <Card title={intl.get(`form.title.${mode}_classrooms`)} bordered
          extra={error && 
            <span style={{fontSize: 16, color: "red"}}><WarningTwoTone twoToneColor={"orange"} style={{marginRight: 12}}/>
              {(error.response && intl.get(`problems.${error.response.data}`, `Une erreur est survenue: ${error.response.data}`)) ||
              (error.message && `Une erreur est survenue: ${error.message}`)}
            </span>}>
        <Form {...layout} layout="vertical" initialValues={{...current, teacherId: current.teacher?.id}} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="name" label={intl.get("labels.columns.name")} rules={rules.name}>
                  <Input allowClear/>
                </Form.Item>
              </Col>
              {current.id !== undefined && <Col span={12} hidden>
                <Form.Item name="id" label="ID">
                  <Input readOnly/>
                </Form.Item>
              </Col>}
              <Col span={24}>
                <Form.Item name="description" label={intl.get("labels.columns.description")} rules={rules.description} labelCol={{offset: 1, span: 11}} wrapperCol={{offset: 1, span: 22}}>  
                  <Input.TextArea allowClear/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="defaultLevel" label={intl.get("labels.columns.level")} rules={rules.defaultLevel}>
                  <LevelSelect allowClear/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="teacherId" label={intl.get("labels.columns.teacher")} rules={rules.teacher}>
                  <Select disabled={teachers?.length === 0} allowClear
                      suffixIcon={(teachers?.length === 0 && <PlusCircleTwoTone onClick={() => history.push({pathname: "/account/teachers/new", state: "/classrooms/new"})} twoToneColor="red"/>) || undefined}>
                    {teachers?.map(teacher => (<Select.Option key={teacher.id} value={teacher.id || ""}>{`${teacher.firstName} ${teacher.lastName} (${teacher.login})`}</Select.Option>))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          <Divider/>
          <Form.Item style={{marginBottom: 0}}>
            <Row style={{ textAlign: "center" }}>
              <Col span={8}>
                <Button onClick={() => setSuccess(true)} icon={<ArrowLeftOutlined/>}>{intl.get("labels.back")}</Button>
              </Col>
              <Col span={8}>
                {mode === "edit" && <>
                  <Button loading={deleting} onClick={() => setAskDelete(true)} danger icon={<DeleteOutlined/>}>{intl.get("labels.delete")}</Button>
                  <Modal title={<ExclamationCircleTwoTone twoToneColor="red"/>} visible={askDelete} 
                      cancelText={intl.get("buttons.cancel")} okText={intl.get("buttons.valid")}
                      onOk={handleDelete} onCancel={() => setAskDelete(false)}>
                    <p>{intl.get("messages.sure")}</p>
                  </Modal>
                </>}
              </Col>
              <Col span={8}>
                <Button loading={saving} htmlType="submit" type="primary" icon={<SaveOutlined/>}>{intl.get("labels.save")}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ClassroomForm;
