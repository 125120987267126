import React from "react";

import * as intl from "../../utils/intl";

import { Form, Row, Col, Input, Button, Card, Divider, Skeleton, Select, Modal } from "antd";
import { InfoCircleOutlined, ExclamationCircleTwoTone, PlusCircleTwoTone, WarningTwoTone, ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useAccount, useEdited } from "../../services";
import { StudentModel, initialStudent } from "../../services/models";
import LevelSelect from "../../components/Layout/LevelSelect";

const layout = {
  labelCol: { offset: 2, span: 10 },
  wrapperCol: { offset: 2, span: 20 },
};

interface Params {
  id:string
}

const StudentForm = () => {
  const history = useHistory();

  const params = useParams<Params>();

  const mode = params?.id ? "edit" : "create";

  const { classes, loadClasses, setCurrentSchool, currentSchool } = useAccount();
  const { loadStudent, deleteStudent, saveStudent } = useEdited();

  const [current, setCurrent] = React.useState<StudentModel>(initialStudent);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<any>();
  const [success, setSuccess] = React.useState<boolean>(false);

  const setModificationOk = () => {
    setSuccess(true);
    setCurrentSchool(currentSchool.id);
  }

  React.useEffect(() => {
    loadClasses();
  }, [loadClasses]);
  
  React.useEffect(() => {
    if(params.id) {
      loadStudent(params.id)
      .then(setCurrent)
      .catch(setError)
      .finally(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
  }, [ loadStudent, params.id]);
  
  React.useEffect(() => {
    if(deleting) {
      setError("");
      deleteStudent(params.id)
      .then(setModificationOk)
      .catch(setError)
      .finally(() => setDeleting(false));
    } else if (saving) {
      setError("");
      saveStudent(current)
      .then(setModificationOk)
      .catch(setError)
      .finally(() => setSaving(false));
    }
  }, [current, deleteStudent, deleting, params.id, saveStudent, saving]);
  
  const onFinish = (values:StudentModel) => {
    setCurrent(values);
    setSaving(true);
  }
  
  const handleDelete = () => {
    setAskDelete(false);
    setDeleting(true);
  };

  const rules = {
    lastName: [{ required: false, message: intl.get("form.rules.required") }],
    firstName: [{ required: false, message: intl.get("form.rules.required") }],
    login: [{ required: true, message: intl.get("form.rules.required") }],
    password: [{ required: false, message: intl.get("form.rules.required") }],
    email: [{ required: false, message: intl.get("form.rules.required") }],
    classroom: [{ required: true, message: intl.get("form.rules.required") }],
    currentLevel: [{ required: false, message: intl.get("form.rules.required") }],
  };

  const getPasswordHelp = () => {
    if (error && error.response.data === "INVALID_PASSWORD") {
      return intl.get("form.rules.password", error.response.data);
    }
    return intl.get(`form.rules.login_${mode}`);
  }

  return !loaded ? <Skeleton/> : (success ? <Redirect push to="/account/students"/> : <>
      <Card title={intl.get(`form.title.${mode}_students`)} bordered
          extra={error && 
            <span style={{fontSize: 16, color: "red"}}><WarningTwoTone twoToneColor={"orange"} style={{marginRight: 12}}/>
              {(error.response && intl.get(`problems.${error.response.data}`, `Une erreur est survenue: ${error.response.data}`)) ||
              (error.message && `Une erreur est survenue: ${error.message}`)}
            </span>}>
        <Form {...layout} layout="vertical" initialValues={current} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="lastName" label={intl.get("labels.columns.name")} rules={rules.lastName}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="firstName" label={intl.get("labels.columns.firstname")} rules={rules.firstName}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="login" label={intl.get("labels.columns.login")} rules={rules.login}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="password" label={intl.get("labels.columns.password")} rules={rules.password} help={getPasswordHelp()}
                  tooltip={{ title: intl.get("form.rules.password"), icon: <InfoCircleOutlined /> }}>
                <Input.Password autoComplete="new-password" allowClear/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label={intl.get("labels.columns.email")} rules={rules.email}>
                <Input allowClear/>
              </Form.Item>
            </Col>
            {current.id !== undefined && <Col span={12} hidden>
              <Form.Item name="id" label="ID">
                <Input readOnly/>
              </Form.Item>
            </Col>}
            <Col span={12}>
              <Form.Item name="classroom" label={intl.get("labels.columns.classroom")} rules={rules.classroom}>
                <Select disabled={classes?.length === 0} allowClear
                      suffixIcon={(classes?.length === 0 && <PlusCircleTwoTone onClick={() => history.push({pathname: "/classrooms/new", state: "/account/students/new"})} twoToneColor="red"/>) || undefined}>
                  {classes?.map(classe => (<Select.Option key={classe.id} value={classe.id || ""}>{`${classe.name} (${classe.school})`}</Select.Option>))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="currentLevel" label={intl.get("labels.columns.level")} rules={rules.currentLevel}>
                <LevelSelect/>
              </Form.Item>
            </Col>
          </Row>
          <Divider/>
          <Form.Item style={{marginBottom: 0}}>
            <Row style={{ textAlign: "center" }}>
              <Col span={8}>
                <Button onClick={() => setSuccess(true)} icon={<ArrowLeftOutlined/>}>{intl.get("labels.back")}</Button>
              </Col>
              <Col span={8}>
                {mode === "edit" && <>
                  <Button loading={deleting} onClick={() => setAskDelete(true)} danger icon={<DeleteOutlined/>}>{intl.get("labels.delete")}</Button>
                  <Modal title={<ExclamationCircleTwoTone twoToneColor="red"/>} visible={askDelete} 
                      cancelText={intl.get("buttons.cancel")} okText={intl.get("buttons.valid")}
                      onOk={handleDelete} onCancel={() => setAskDelete(false)}>
                    <p>{intl.get("messages.sure")}</p>
                  </Modal>
                </>}
              </Col>
              <Col span={8}>
                <Button loading={saving} htmlType="submit" type="primary" icon={<SaveOutlined/>}>{intl.get("labels.save")}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default StudentForm;
